import { store } from "@risingstack/react-easy-state";

const groupStore = store({
  groepen: [],
  aanmeldingen: [],
  puntenIngezet: 0,
  volleGroep: [],
  userInfo: {
    voornaam: "",
    achternaam: "",
    emailadres: "",
    motivatie: "",
  },
  aanmeldingDefinitief: [],
  aanmeldingVerstuurd: false,
  //loads all groepen to the store
  addAllToStore(groepen) {
    groupStore.groepen = groepen;
  },
  // AANMELDING
  get zijnErAanmeldingen() {
    return groupStore.aanmeldingen.length !== 0;
  },
  // adds nieuwe aanmelding to the aanmeldingen
  add(nieuweAanmelding) {
    groupStore.aanmeldingen.push(nieuweAanmelding);
    groupStore.puntenIngezet =
      groupStore.puntenIngezet + nieuweAanmelding.punten;
  },
  remove(groep) {
    groupStore.aanmeldingen = groupStore.aanmeldingen.filter(
      (aanmelding) => aanmelding._id !== groep._id,
    );
    if (groupStore.volleGroep.length) {
      groupStore.volleGroep = groupStore.volleGroep.filter(
        (aanmeldingId) => aanmeldingId !== groep._id,
      );
    }

    groupStore.puntenIngezet = groupStore.puntenIngezet - groep.punten;

    return groupStore.aanmeldingen;
  },
  addGroepToBezet(groepId) {
    groupStore.volleGroep.push(groepId);
  },
  setAangemeld() {
    groupStore.aanmeldingen = [];
    groupStore.volleGroep = [];
    groupStore.puntenIngezet = [];
    groupStore.userInfo = {
      voornaam: "",
      achternaam: "",
      emailadres: "",
      motivatie: "",
    };
    groupStore.aanmeldingVerstuurd = true;
  },
  isGeselecteerd(groepId) {
    return groupStore.aanmeldingen.map((i) => i._id).includes(groepId);
  },
});

export default groupStore;
