import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  staticBackground: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: (props) => props.staticBackgroundColor,
    position: "fixed",
  },

  movingTriangle: {
    left: "85vw",
    width: "2000px",
    height: "2000px",
    backgroundColor: (props) => props.triangleColor,
    position: "fixed",
    transform: "rotateY(0deg) rotate(45deg)",
    zIndex: 2,
    overflow: "hidden",
    ["@media (max-width:1000px)"]: {
      left: "78vw",
      width: "1400px",
      height: "1400px",
    },
  },

  movingBackground: {
    position: "fixed",
    width: "150vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: (props) => props.movingBackgroundColor,
  },

  pageContainer: {
    padding: "150px",
    position: "absolute",
    zIndex: 3,
    maxWidth: "780px",
    margin: "0 auto",
    ["@media (max-width:1000px)"]: {
      maxWidth: "100%",
      padding: "10%",
      margin: "10% 0 0 0",
    },
  },
  page: {
    zIndex: 9,
  },
  menuContainer: {
    position: "fixed",
    display: "flex",
    margin: "30px 50px",
    zIndex: 4,
    ["@media (max-width:1000px)"]: {
      margin: "20px",
    },
  },
  menuItem: {
    margin: "0 10px",
  },
});
