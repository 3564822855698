import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import ReactPlayer from "react-player/lazy";
import { styles } from "./Video.styled";

export default function VideoComp({ item }) {
  const { url, autoplay } = item;
  const wrapperRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const aspectRatio = 9 / 16; // Assume a 16:9 aspect ratio
    setHeight(width * aspectRatio);
  }, [width]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        setWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={styles.wrapper}
      ref={wrapperRef}
    >
      <ReactPlayer
        url={url}
        muted={false}
        playing={autoplay}
        width={styles.wrapper.width}
        controls={true}
        height={height}
      />
    </Box>
  );
}
