import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, IconButton, Paper, Typography } from "@mui/material/";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AfbeeldingToevoegen from "./AfbeeldingToevoegen";
import Preview from "./Preview";
import Afbeelding from "./Afbeelding";
import Groep from "./Groep";
import { useStyles } from "./tentKamerIndeling.styles";

export default function TentkamerIndeling({ snackbar }) {
  const classes = useStyles();
  const [images, setImages] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    axios
      .get("api/sleepingPlan")
      .then((response) => {
        setImages(response.data.images)
        setGroups(response.data.groups)
      })
      .catch((error) => {
        snackbar("Er is iets misgegaan", "error")
      });
  }, []);

  const handleAddGroup = () => {
    axios.post("/api/sleepingPlan/addNewGroup")
    .then((response) => {
      if (response.status === 201) {
        setGroups((prevGroups) => [...prevGroups, response.data]);
      } else {
        throw Error;
      }
    })
    .catch((error) => {
        snackbar("Er is iets misgegaan", "error")
    });
  };

  return (
    <Paper className={classes.root}>
      <Box p={1}>
        <Typography variant="h5">Afbeeldingen</Typography>
        <Box className={classes.imgDisplayer}>
          {images.map((image) => {
            return (
              <Afbeelding
                key={image._id}
                image={image}
                images={images}
                snackbar={snackbar}
                setImages={setImages}
              />
            );
          })}
          <AfbeeldingToevoegen snackbar={snackbar} setImages={setImages} />
        </Box>
        <Preview images={images} />
      </Box>
      <Box p={1}>
        <Typography variant="h5">Groepen</Typography>
        <Box className={classes.groepenContainer}>
          {groups.map((group) => {
            return (
              <Groep
                key={group._id}
                group={group}
                groups={groups}
                snackbar={snackbar}
                setGroups={setGroups}
              />
            );
          })}
          <IconButton
            size="small"
            aria-label="addGroep"
            className={classes.addButton}
            color="primary"
            onClick={handleAddGroup}
          >
            <AddCircleIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
