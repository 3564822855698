import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import userStore from "../store/userStore";
import AccessDenied from "../components/errorPage/AccessDenied";

const ProtectedRoute = ({ allowedRoles }) => {
  const userInfo = userStore.userInfo;
  if (!userInfo) {
    return <Navigate to="/login" />;
  }

  if (!userInfo.role || !allowedRoles.includes(userInfo.role)) {
    return <AccessDenied />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default view(ProtectedRoute);
