import makeStyles from "@mui/styles/makeStyles";
import Karavaan2022Logo from "../../images/Karavaan2022Logo.png";
import Karavaan2022LogoMobile from "../../images/Karavaan2022LogoMobile.png";
import ChateauKaravaanBackground from "../../images/ChateauKaravaanBackground.png";
import ChateauKaravaanLogoMobile from "../../images/ChateauKaravaanLogoMobile.png";
import settingsStore from "../../store/settingsStore";

export const useStyles = makeStyles(() => {
  return {
    bgImage: {
      backgroundImage: `url(${
        settingsStore.general.theme === "winter"
          ? ChateauKaravaanBackground
          : Karavaan2022Logo
      })`,
      backgroundSize: "100vw",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      width: "100vw",
      height: "100vh",
      backgroundColor:
        settingsStore.general.theme === "winter" ? "#9fc2fb" : "#38a3a5",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      placeItems: "center",
      position: "relative",
      ["@media (max-width:900px)"]: {
        backgroundImage: `url(${
          settingsStore.general.theme === "winter"
            ? ChateauKaravaanLogoMobile
            : Karavaan2022LogoMobile
        })`,
      },
    },
    introTextLogo: {
      marginTop: "7%",
      width: "35%",
      ["@media (max-width:900px)"]: {
        marginTop: "50%",
        width: "50%",
      },
    },
    trees: {
      width: "100%",
    },
    treesContainer: {
      display: "flex",
      alignItems: "flex-end",
      position: "absolute",
      width: "100%",
      height: "100%",
    },
  };
});
