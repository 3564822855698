import React from "react";
import { Box, Typography } from "@mui/material";
import Paragraph from "../../../common/Paragraph";

export default function ParagraphComp({ item: { header, text } }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {header && <Typography variant="h3">{header}</Typography>}
      <Paragraph paragraph={text} />
    </Box>
  );
}
