import React, { useState, useEffect } from "react";
import axios from "axios";
import { nanoid } from "nanoid";
import { Box } from "@mui/material";
import { HeaderComp, ParagraphComp, ImageComp, VideoComp } from "./pageContent";

const DynamicContentWrapper = ({ pageId, children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `api/settings/pages/content/${pageId}`,
        );
        setPageData(response.data.content);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };

    fetchData();
  }, [pageId]);

  const renderItem = (item) => {
    switch (item.type) {
      case "header":
        return <HeaderComp key={nanoid()} item={item} />;

      case "paragraph":
        return <ParagraphComp key={nanoid()} item={item} />;

      case "image":
        return <ImageComp key={nanoid()} item={item} />;

      case "video":
        return <VideoComp key={nanoid()} item={item} />;

      default:
        break;
    }
  };
  if (loading) {
    return <div>loading..</div>;
  }
  if (error) {
    return (
      <div>Error bij het ophalen van de data. Probeer het later nog eens</div>
    );
  }
  return (
    <Box>
      {pageData.map((item) => (
        <Box key={nanoid()} sx={{ padding: "8px 0px" }}>
          {renderItem(item)}
        </Box>
      ))}
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          pageData,
        }),
      )}
    </Box>
  );
};

export default DynamicContentWrapper;
