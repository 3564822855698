import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import XPress from "../_utils/Xpress";
import Timeline from "@mui/lab/Timeline";
import TijdlijnItem from "./TijdlijnItem";

const useStyles = makeStyles({
  timeline: {
    width: "100%",
    ["@media (max-width:500px)"]: {
      padding: "6px 0",
    },
  },
});

export default function Tijdlijn({ day }) {
  const classes = useStyles();
  const [data, setData] = useState();

  useEffect(() => {
    XPress.getArray("api/program/visible").then((data) => {
      if (data) {
        setData(data);
      }
    });
  }, []);

  const timeLineItems = () => {
    let filteredData = data.filter((item) => item.day === day);
    return filteredData
      .sort(function (a, b) {
        return a.time.localeCompare(b.time);
      })
      .map((item, index) => {
        return <TijdlijnItem key={item._id} value={item} nummer={index} />;
      });
  };

  if (data) {
    return (
      <Timeline className={classes.timeline} align="alternate">
        {timeLineItems()}
      </Timeline>
    );
  } else {
    return <div>Data aan het laden..</div>;
  }
}
