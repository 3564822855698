import React, { useEffect, useState } from "react";
import { Intro } from "../Intro/Intro";
import { Locatie } from "../locatie/Locatie";
import { Throwback } from "../throwback/Throwback";
import { EigenIdee } from "../eigenidee/EigenIdee";
import { Formulier } from "../formulier/Formulier";
import { Tentindeling } from "../tentindeling/Tentindeling";
import { Bootindeling } from "../bootindeling/Bootindeling";
import { Betaling } from "../betaling/Betaling";
import { Brievenbus } from "../brievenbus/Brievenbus";
import { Programma } from "../Programma/Programma";
import GroepenOverzicht from "../groepenoverzicht/GroepenOverzicht";
import BorgTerug from "../borgTerug";

const components = {
  intro: Intro,
  locatie: Locatie,
  throwback: Throwback,
  eigenIdee: EigenIdee,
  betaling: Betaling,
  brievenbus: Brievenbus,
  programma: Programma,
  groepenOverzicht: GroepenOverzicht,
  formulier: Formulier,
  tentIndeling: Tentindeling,
  bootIndeling: Bootindeling,
  borgTerug: BorgTerug
};

function DynamicComponent({ currentPage, delay = 0 }) {
  const [selectedComponent, setSelectedComponent] = useState(currentPage);
  const SelectComponent = components[selectedComponent];

  useEffect(() => {
    setTimeout(() => {
      setSelectedComponent(currentPage);
    }, delay);
  }, [currentPage]);

  return <SelectComponent />;
}

export default DynamicComponent;
