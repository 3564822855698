import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  addButton: {
    marginLeft: "1%",
    height: "100%",
  },
  img: {
    maxWidth: 200,
    maxHeight: 200,
  },
  groepenContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  imgContainer: {
    width: 200,
    height: 200,
    border: "1px solid lightgrey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgDisplayer: {
    display: "flex",
    overflowX: "scroll",
  },
  imgItem: {
    border: "1px solid lightgrey",
  },
  root: {
    width: "100%",
  },
  smallColumn: {
    maxWidth: "100px",
  },
});
