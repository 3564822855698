import React, { useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import "./Formulier.css";

const vega = [
  {
    value: "vega",
    label: "Vega",
  },
  {
    value: "niet vega",
    label: "Niet vega",
  },
];

const vaartijdenHeenweg = [
  {
    value: "13:30",
    label: "13:30 uur (LET OP: Alleen mogelijk voor de opbouwers + meerijders)",
  },
  {
    value: "16:00",
    label: "16:00 uur",
  },
  {
    value: "18:00",
    label: "18:00 uur",
  },
  {
    value: "20:00",
    label: "20:00 uur",
  },
  {
    value: "na 20 uur",
    label: "Ik kan er pas later dan 20:00 uur zijn",
  },
];

const vaartijdTerugweg = [
  {
    value: "maandag",
    label: "Ik ga maandag (rond het middaguur) terug",
  },
  {
    value: "zondag",
    label: "Ik moet echt zondag terug",
  },
];

const vervoer = [
  {
    value: "ja",
    label: "Ja",
  },
  {
    value: "nee",
    label: "Nee, help mij dit te regelen",
  },
];

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  inputField: {
    marginBottom: 15,
  },

  ["@media (max-width:500px)"]: {
    menuItem: {
      fontSize: "0.8rem",
      whiteSpace: "unset",
      wordBreak: "break-all",
    },
  },
}));

export function FormulierForm() {
  const classes = useStyles();
  const ref = useRef({});
  const [status, setStatus] = useState();
  const [data, setData] = useState({
    voornaam: "",
    achternaam: "",
    vega: "vega",
    dieetwensen: "",
    vervoer: "ja",
    vaartijdHeen: "16:00",
    vaartijdTerug: "zondag",
    track: "",
  });
  const handleInputChange = (attribute) => (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [attribute]: value,
    });
  };

  async function vraagVerzenden(e) {
    e.preventDefault();
    fetch("/overview/berichten/formulier", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    }).then((res) => {
      if (res.status === 201) {
        setStatus("We hebben 'm ontvangen! Tot over een paar weken!");
      } else {
        const error = new Error(res.error);
        console.log(error);
        setStatus(
          "⁉️🤖 Er is iets misgegaan! Refresh de pagina en probeer het opnieuw, probeer anders een andere browser of probeer het op een ander apparaat.",
        );
      }
    });
  }
  if (status) {
    return <Box mt={3}>{status}</Box>;
  }
  return (
    <form onSubmit={(e) => vraagVerzenden(e)} autoComplete="off">
      <FormControl
        fullWidth
        className={classes.form}
        variant="filled"
        margin="dense"
      >
        <TextField
          required
          className={classes.inputField}
          id="voornaam"
          label="Voornaam"
          inputRef={(el) => (ref.current["voornaam"] = el)}
          onBlur={handleInputChange("voornaam")}
        />
        <TextField
          required
          className={classes.inputField}
          id="achternaam"
          label="Achternaam"
          inputRef={(el) => (ref.current["achternaam"] = el)}
          onBlur={handleInputChange("achternaam")}
        />
        <TextField
          id="vega"
          select
          className={classes.inputField}
          required
          label="Vega / Niet vega"
          value={data.vega}
          onChange={handleInputChange("vega")}
          inputRef={(el) => (ref.current["vega"] = el)}
        >
          {vega.map((option) => (
            <MenuItem
              className={classes.menuItem}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          className={classes.inputField}
          id="dieetwensen"
          label="Allergieën"
          multiline
          maxRows={2}
          inputRef={(el) => (ref.current["dieetwensen"] = el)}
          onBlur={handleInputChange("dieetwensen")}
        />
        <TextField
          id="vervoer"
          select
          className={classes.inputField}
          classes={{ root: classes.root }}
          required
          label="Vervoer"
          value={data.vervoer}
          onChange={handleInputChange("vervoer")}
          helperText="Heb je al vervoer geregeld?"
          inputRef={(el) => (ref.current["vervoer"] = el)}
        >
          {vervoer.map((option) => (
            <MenuItem
              className={classes.menuItem}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="vaartijdenHeen"
          select
          className={classes.inputField}
          required
          label="Boottocht heenreis"
          value={data.vaartijdHeen}
          onChange={handleInputChange("vaartijdHeen")}
          helperText="Verwachtte aankomsttijd"
          inputRef={(el) => (ref.current["vaartijdHeen"] = el)}
        >
          {vaartijdenHeenweg.map((option) => (
            <MenuItem
              className={classes.menuItem}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="vaartijdTerug"
          select
          className={classes.inputField}
          required
          label="Boottocht terugreis"
          value={data.vaartijdTerug}
          onChange={handleInputChange("vaartijdTerug")}
          helperText="We zijn beperkt in het aantal vaarten die we kunnen maken. We gaan er vanuit dat iedereen tot maandag blijft. Moet jij toch echt zondag al van het eiland af, geef dit dan nu aan. Wij nemen dan even contact met je op."
          inputRef={(el) => (ref.current["vaartijdTerug"] = el)}
        >
          {vaartijdTerugweg.map((option) => (
            <MenuItem
              className={classes.menuItem}
              key={option.value}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required
          className={classes.inputField}
          id="trackId"
          label="Ultieme Karavaan track"
          inputRef={(el) => (ref.current["voornaam"] = el)}
          helperText="Welk nummer zou jij omschrijven als hét nummer om in de mood te komen voor een geweldig weekend op een eiland met allemaal leuke mensen om je heen? Vul Artiest en titel in."
          onBlur={handleInputChange("track")}
        />
        <div className="buttondiv">
          <Button type="submit" className={classes.button}>
            Verstuur
          </Button>
        </div>
      </FormControl>
    </form>
  );
}
