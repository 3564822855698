import React, { useState, useEffect } from "react";
import XPress from "../../_utils/Xpress";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function FormulierInzendingen() {
  const classes = useStyles();
  const [data, setData] = useState();

  useEffect(() => {
    XPress.getArray("overview/berichten/formulier").then((data) => {
      if (data) {
        setData(data);
      }
    });
  }, []);

  function deleteFormulierInzending(id) {
    XPress.deleteItem("overview/berichten/formulier", id).then(() => {
      setData((prevState) => prevState.filter((i) => i._id !== id));
      detailsMap();
    });
  }

  function deleteAllFormulierInzendingem(id) {
    XPress.deleteItems("overview/berichten/formulier").then(() => {
      setData([]);
      detailsMap();
    });
  }
  const getLink = (link) => {
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = `https://${link}`;
    }
    return link;
  };

  function detailsMap() {
    if (data) {
      return data.map((item) => {
        return (
          <TableRow key={item._id}>
            <TableCell component="th" scope="row">
              {item.voornaam} {item.achternaam}
            </TableCell>
            {/* <TableCell>
              <a href={getLink(item.link)} target="_blank" rel="noopener">
                {item.link}
              </a>
            </TableCell> */}
            <TableCell>{item.dieetwensen}</TableCell>
            <TableCell>{item.vega}</TableCell>
            <TableCell>{item.vervoer}</TableCell>
            <TableCell>{item.vaartijdHeen}</TableCell>
            <TableCell>{item.vaartijdTerug}</TableCell>
            <TableCell>{item.track}</TableCell>
            <TableCell align="center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() =>
                  window.confirm(
                    "Weet je zeker dat je deze inzending wilt verwijderen?",
                  ) && deleteFormulierInzending(item._id)
                }
              >
                Verwijder
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    }
  }

  if (!data) {
    return "Data aan het laden";
  }
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Naam ({data.length}) </TableCell>
            {/* <TableCell align="left">Link</TableCell> */}
            <TableCell align="left">Dieetwensen</TableCell>
            <TableCell align="left">Vega?</TableCell>
            <TableCell align="left">Vervoer</TableCell>
            <TableCell align="left">Aankomsttijd</TableCell>
            <TableCell align="left">Zondag terug?</TableCell>
            <TableCell align="left">Ingestuurde track</TableCell>
            <TableCell align="center">
              Verwijderen{" "}
              <Button
                variant="contained"
                color="secondary"
                disabled={!data.length}
                onClick={() =>
                  window.confirm(
                    "Weet je zeker dat je ALLE inzending wilt verwijderen?",
                  ) && deleteAllFormulierInzendingem()
                }
              >
                Verwijder Alles
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{detailsMap()}</TableBody>
      </Table>
    </TableContainer>
  );
}
