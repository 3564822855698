import React, { useEffect, useRef, useState } from "react";
import { Box, Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { styles } from "./styled";
import { LeftMenuItem } from "./LeftMenuItem";
import { RightContent } from "./RightContent";
import { ContentType } from "../../../../../../constants/ContentType";
import { useDidMountEffect } from "../../../../../../hooks/useDidMountEffect";

export default function PageContentComp({ id }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/settings/pages/content/${id}`)
        .then((res) => {
          if (res.status === 200) {
            setContent(res.data.content);
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching page content:", error);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useDidMountEffect(() => {
    if (!loading) {
      axios
        .put(`/api/settings/pages/content/${id}`, {
          config: content,
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [content, id]);

  const updateContentItem = (id, itemToUpdate) => {
    const newContent = content.map((item) => {
      if (item.id === id) {
        return itemToUpdate;
      }
      return item;
    });
    setContent(newContent);
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  if (error) {
    return <Box>Error bij het ophalen van de pagina gegevens...</Box>;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={styles.wrapper}>
        <Grid
          sx={{ width: "100%" }}
          container
          spacing={0.5}
          disableEqualOverflow
        >
          <Grid xs={4} md={2}>
            <Card sx={styles.gridItem}>
              <LeftMenuItem
                itemType={ContentType.HEADER}
                name="Pagina header"
              />
              <LeftMenuItem itemType={ContentType.PARAGRAPH} name="Tekstblok" />
              <LeftMenuItem itemType={ContentType.VIDEO} name="Video" />
              <LeftMenuItem itemType={ContentType.IMAGE} name="Afbeelding" />
            </Card>
          </Grid>
          <Grid xs={8} md={10}>
            <Card sx={styles.gridItem}>
              <RightContent
                content={content}
                setContent={setContent}
                updateContentItem={updateContentItem}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DndProvider>
  );
}
