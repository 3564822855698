import { createTheme } from "@mui/material/styles";
import colors from "./store";
import kleur1 from "../../images/kleurBalken/kleur1.png";
import kleur2 from "../../images/kleurBalken/kleur2.png";
import kleur3 from "../../images/kleurBalken/kleur3.png";
import kleur4 from "../../images/kleurBalken/kleur4.png";
import kleur5 from "../../images/kleurBalken/kleur5.png";
import kleur6 from "../../images/kleurBalken/kleur6.png";
import kleur7 from "../../images/kleurBalken/kleur7.png";
import kleur8 from "../../images/kleurBalken/kleur8.png";
import kleur9 from "../../images/kleurBalken/kleur9.png";
import kleur10 from "../../images/kleurBalken/kleur10.png";
import kleur11 from "../../images/kleurBalken/kleur11.png";

const theme = createTheme({
  itemBackgroundColor: colors.getButtonColor,
  topbarImage: [
    kleur1,
    kleur2,
    kleur3,
    kleur4,
    kleur5,
    kleur6,
    kleur7,
    kleur8,
    kleur9,
    kleur10,
    kleur11,
  ],
  wideContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "60vw",
    ["@media (max-width:1000px)"]: {
      width: "85vw",
    },
  },
  button: {
    backgroundColor: colors.getButtonColor,
    borderRadius: 4,
    border: "1px solid rgba(0,0,0,0.07)",
    marginBottom: "5%",
    padding: 10,
    width: "45%",
    color: "#023626",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    "&:hover": {
      boxShadow: "none",
    },
    "& &:focus, &:visited, &:hover, &:active, &:link": {
      backgroundColor: colors.getButtonColor,
      textDecoration: "none",
      "-webkit-filter": "brightness(110%)",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
  typography: {
    h2: {
      fontFamily: "Cairo, sans-serif",
      fontWeight: "bold",
      color: "#023626",
      fontSize: "3em",
      maxWidth: "100%",
      ["@media (max-width:1000px)"]: {
        fontSize: "1.7rem",
      },
    },
    h3: {
      fontFamily: "Cairo, sans-serif",
      fontWeight: "bold",
      color: "#023626",
      fontSize: "2em",
      maxWidth: "100%",
      ["@media (max-width:1000px)"]: {
        fontSize: "1.5em",
      },
    },
    h4: {
      fontFamily: "Cairo, sans-serif",
      fontWeight: "bold",
      textAlign: "center",
      color: "#023626",
      fontSize: "1.1em",
      maxWidth: "100%",
      ["@media (max-width:1000px)"]: {
        fontSize: "0.8em",
      },
    },
    body1: {
      fontFamily: "Cairo, sans-serif",
      fontSize: "18px",
      color: "rgb(6, 40, 70)",
    },
  },
  palette: {
    logoColor1: {
      backgroundColor: "#95d2e4",
      color: "#FFF",
      margin: 0,
    },
    logoColor2: {
      backgroundColor: "#77c09b",
      color: "#FFF",
      margin: 0,
    },
    logoColor3: {
      backgroundColor: "#9dd5e0",
      color: "#FFF",
      margin: 0,
    },
    logoColor4: {
      backgroundColor: "#feee63",
      color: "#FFF",
      margin: 0,
    },
    logoColor5: {
      backgroundColor: "#ffcecd",
      color: "#FFF",
      margin: 0,
    },
    logoColor6: {
      backgroundColor: "#de5599",
      color: "#FFF",
      margin: 0,
    },
    logoColor7: {
      backgroundColor: "#c0c0e2",
      color: "#FFF",
      margin: 0,
    },
  },
});

export default theme;
