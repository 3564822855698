import LocalDiningIcon from "@mui/icons-material/LocalDining";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import PaletteIcon from "@mui/icons-material/Palette";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import TerrainIcon from "@mui/icons-material/Terrain";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import FlareIcon from "@mui/icons-material/Flare";
import theme from "../app/theme";
import kleur1 from "../../images/kleurBalken/kleur1.png";
import kleur2 from "../../images/kleurBalken/kleur2.png";
import kleur3 from "../../images/kleurBalken/kleur3.png";
import kleur4 from "../../images/kleurBalken/kleur4.png";
import kleur5 from "../../images/kleurBalken/kleur5.png";
import kleur6 from "../../images/kleurBalken/kleur6.png";
import kleur7 from "../../images/kleurBalken/kleur7.png";
import kleur8 from "../../images/kleurBalken/kleur8.png";
import kleur9 from "../../images/kleurBalken/kleur9.png";
import kleur10 from "../../images/kleurBalken/kleur10.png";
import kleur11 from "../../images/kleurBalken/kleur11.png";

export const iconPicker = (iconName) => {
  if (iconName === "icon1") {
    return {
      styling: theme.palette.logoColor2,
      logo: LocalDiningIcon,
      topbar: kleur1,
    };
  } else if (iconName === "icon2") {
    return {
      styling: theme.palette.logoColor5,
      logo: OutdoorGrillIcon,
      topbar: kleur2,
    };
  } else if (iconName === "icon3") {
    return {
      styling: theme.palette.logoColor3,
      logo: FastfoodIcon,
      topbar: kleur3,
    };
  } else if (iconName === "icon4") {
    return {
      styling: theme.palette.logoColor4,
      logo: PhotoCameraIcon,
      topbar: kleur4,
    };
  } else if (iconName === "icon5") {
    return {
      styling: theme.palette.logoColor5,
      logo: LocalBarIcon,
      topbar: kleur5,
    };
  } else if (iconName === "icon6") {
    return {
      styling: theme.palette.logoColor5,
      logo: PaletteIcon,
      topbar: kleur6,
    };
  } else if (iconName === "icon7") {
    return {
      styling: theme.palette.logoColor7,
      logo: ThreeSixtyIcon,
      topbar: kleur7,
    };
  } else if (iconName === "icon8") {
    return {
      styling: theme.palette.logoColor2,
      logo: SmokingRoomsIcon,
      topbar: kleur8,
    };
  } else if (iconName === "icon9") {
    return {
      styling: theme.palette.logoColor3,
      logo: TerrainIcon,
      topbar: kleur9,
    };
  } else if (iconName === "icon10") {
    return {
      styling: theme.palette.logoColor6,
      logo: QueueMusicIcon,
      topbar: kleur10,
    };
  } else {
    return {
      styling: theme.palette.logoColor1,
      logo: FlareIcon,
      topbar: kleur11,
    };
  }
};
