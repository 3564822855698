import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import ImageUploader from "../../../../../../common/imageUploader";
import { styles } from "./styled";

export default function ImageComp({
  item: { id, type, url, alt },
  updateContentItem,
}) {
  const [altValue, setAltValue] = useState(alt);

  const handleUrlChange = (url) => {
    updateContentItem(id, {
      id,
      type,
      url: url,
      alt,
    });
  };

  const handleAltChange = () => {
    updateContentItem(id, {
      id,
      type,
      url,
      alt: altValue,
    });
  };
  return (
    <Box sx={styles.imageWrapper}>
      <ImageUploader
        text="Select image"
        path="projects/highres"
        value={url}
        setUrl={handleUrlChange}
      />
      <TextField
        name="alt"
        value={altValue}
        onChange={(e) => setAltValue(e.target.value)}
        placeholder="Add alt text"
        fullWidth
        onBlur={handleAltChange}
      />
    </Box>
  );
}
