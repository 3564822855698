import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { view } from "@risingstack/react-easy-state";

const useStyles = makeStyles((theme) => ({
  vraagContainer: {
    backgroundColor: theme.itemBackgroundColor,
    borderRadius: "4px",
  },
}));

function Faqvraag({ question }) {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [arrow, setArrow] = useState("left");

  function toggleClickedFAQ() {
    setClicked(!clicked);
    setArrow(arrow === "left" ? "down" : "left");
  }

  return (
    <div className={classes.vraagContainer}>
      <div className="vraag" onClick={toggleClickedFAQ}>
        {question.question}
        <i className={arrow}></i>
      </div>
      {clicked ? <div className="antwoord">{question.answer}</div> : null}
    </div>
  );
}

export default view(Faqvraag);
