import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Typography } from "@mui/material";
import { storage } from "../../_utils/firebase";
import img_placeholder from "./img_placeholder.png";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginTop: "2%",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 250,
  },
  img: {
    maxWidth: 200,
    maxHeight: 200,
  },
});

export default function ImageUploader({ url, text, setUrl  }) {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState("");
  const [imagePreview, setImagePreview] = useState(url);

  useEffect(() => {
    setImagePreview(url);
    if (!url) {
      setStatus("")
    }
  }, [url]);

  const handleFireBaseUpload = () => {
    return new Promise((resolve, reject) => {
      if (file === "") {
        setStatus(`Geen afbeelding, dit is een ${typeof file} bestand`);
      }
      const uploadTask = storage.ref(`/images/${file.name}`).put(file);
      uploadTask.on("state_changed", console.log, console.error, () => {
        storage
          .ref("images")
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            setStatus("Afbeelding geupload!");
            setUrl(url);
            setFile(null);
            resolve(url);
          });
      });
    });
  };

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    if (image) {
      setFile(image);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    } else if (imagePreview) {
      setFile(null);
      setImagePreview(null);
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <Box p={1}>
          <img
            className={classes.img}
            src={imagePreview || img_placeholder}
            alt=""
          />
        </Box>
        <Box p={1}>
          <Button
            variant="contained"
            component="label"
            onClick={() => file && handleFireBaseUpload()}
          >
            {!file && (
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => !file && handleImageAsFile(e)}
              />
            )}
            {!file ? text : "Upload"}
          </Button>
        </Box>
        {status && <Typography>{status}</Typography>}
      </div>
    </div>
  );
}
