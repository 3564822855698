import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { view } from "@risingstack/react-easy-state";
import TextFieldInput from "../../common/TextField";

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.button,
  },
}));

function StuurVraag() {
  const classes = useStyles();
  const [vraag, setVraag] = useState("");
  const [naam, setNaam] = useState("");
  const [error, setError] = useState();
  const [verstuurd, setVerstuurd] = useState(false);

  function removeVerstuurd() {
    if (verstuurd) {
      setVerstuurd(false);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const newQuestion = {
      question: vraag,
      name: naam,
    };

    setVerstuurd(true);
    setVraag("");
    setNaam("");

    try {
      await axios.post("api/faq", newQuestion, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      setError("Er is iets fout gegaan. probeer het later nog eens");
      console.error("Request failed:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <span className="formtext"></span>
      <div onClick={removeVerstuurd}>
        <TextFieldInput
          value={vraag}
          required
          handleChange={(event) => setVraag(event.target.value)}
          placeholder="Wat is je vraag?"
          multiline
          rows={2}
          margin="normal"
        />
        <TextFieldInput
          value={naam}
          handleChange={(event) => setNaam(event.target.value)}
          placeholder="Optioneel: Naam"
        />
      </div>
      {verstuurd && "Verstuurd! We zullen er eens naar gaan kijken."}

      <div className="buttondiv">
        <Button
          type="submit"
          disabled={vraag === ""}
          className={classes.button}
        >
          Verstuur
        </Button>
      </div>
      {error && <span>{error}</span>}
    </form>
  );
}

export default view(StuurVraag);
