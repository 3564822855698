import React, { useState, useRef } from "react";
import axios from "axios";
import { capitalize } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import LocalDiningIcon from "@mui/icons-material/LocalDining";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import PaletteIcon from "@mui/icons-material/Palette";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import TerrainIcon from "@mui/icons-material/Terrain";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import FlareIcon from "@mui/icons-material/Flare";
import { Days } from "../../../../constants/Days";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 40,
  },
  buttonGroup: {
    marginTop: 10,
    marginBottom: 10,
  },
});

export default function GroepToevoegen(props) {
  const classes = useStyles();
  const ref = useRef({});
  const [status, setStatus] = useState();
  const [data, setData] = useState({
    title: "",
    text: "",
    day: Days.DONDERDAG,
    time: "09:00",
    icon: "icon1",
  });

  const handleInputChange = (attribute) => (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [attribute]: value,
    });
  };

  const handleButtonClick = (e, button) => {
    setData({
      ...data,
      icon: button,
    });
  };

  async function submitNewItem(e) {
    e.preventDefault();
    try {
      const response = await axios.post("api/program", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        setStatus("Item opgeslagen!");
        props.updateProp(props.updateState);
      } else {
        props.snackbar("Er is een fout opgetreden.", "error");
      }
    } catch (error) {
      props.snackbar("Er is een fout opgetreden.", "error");
      setStatus(error.message);
    }
  }
  const resetState = () => {
    setStatus();
    ref.current["title"].value = "";
    ref.current["text"].value = "";
    ref.current["day"].value = Days.DONDERDAG;
    ref.current["time"].value = "09:00";
    setData({
      title: "",
      text: "",
      day: Days.DONDERDAG,
      time: "09:00",
      icon: "icon1",
    });
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={(e) => submitNewItem(e)} autoComplete="off">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="groep-toevoegen-header"
            id="groep-toevoegen"
          >
            <Typography>Voeg een nieuw item toe</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <TextField
                required
                id="title"
                label="Titel"
                inputRef={(el) => (ref.current["title"] = el)}
                onBlur={handleInputChange("title")}
              />
              <TextField
                required
                id="text"
                label="Tekst"
                multiline
                rows={8}
                inputRef={(el) => (ref.current["text"] = el)}
                onBlur={handleInputChange("text")}
              />
              <InputLabel htmlFor="day-native-simple">Dag</InputLabel>
              <Select
                required
                native
                value={data.day}
                inputProps={{
                  name: "day",
                  id: "day-native-simple",
                }}
                onChange={handleInputChange("day")}
                inputRef={(el) => (ref.current["day"] = el)}
              >
                {Object.values(Days).map((day) => {
                  return (
                    <option value={day} key={day}>
                      {capitalize(day)}
                    </option>
                  );
                })}
              </Select>
              <TextField
                id="time"
                required
                label="Tijd"
                type="time"
                defaultValue="09:00"
                inputRef={(el) => (ref.current["time"] = el)}
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={handleInputChange("time")}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <ToggleButtonGroup
                value={data.icon}
                exclusive
                className={classes.buttonGroup}
                onChange={handleButtonClick}
                aria-label="icon-selector"
              >
                <ToggleButton value="icon1" aria-label="icon1">
                  <LocalDiningIcon />
                </ToggleButton>
                <ToggleButton value="icon2" aria-label="icon2">
                  <OutdoorGrillIcon />
                </ToggleButton>
                <ToggleButton value="icon3" aria-label="icon3">
                  <FastfoodIcon />
                </ToggleButton>
                <ToggleButton value="icon4" aria-label="icon4">
                  <PhotoCameraIcon />
                </ToggleButton>
                <ToggleButton value="icon5" aria-label="icon5">
                  <LocalBarIcon />
                </ToggleButton>
                <ToggleButton value="icon6" aria-label="icon6">
                  <PaletteIcon />
                </ToggleButton>
                <ToggleButton value="icon7" aria-label="icon7">
                  <ThreeSixtyIcon />
                </ToggleButton>
                <ToggleButton value="icon8" aria-label="icon8">
                  <SmokingRoomsIcon />
                </ToggleButton>
                <ToggleButton value="icon9" aria-label="icon9">
                  <TerrainIcon />
                </ToggleButton>
                <ToggleButton value="icon10" aria-label="icon10">
                  <QueueMusicIcon />
                </ToggleButton>
                <ToggleButton value="icon11" aria-label="icon11">
                  <FlareIcon />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={status ? true : false}
              >
                {status ? status : "Voeg item toe"}
              </Button>
              {status && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={resetState}
                >
                  Voeg nog een item toe
                </Button>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>
    </Box>
  );
}
