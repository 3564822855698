import React from "react";
import { Box, Tooltip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useStyles } from "./SingleToggleGroup.styles";

function SingleToggleGroup({
  value,
  setValue,
  items,
  disabled = false,
  primaryColor = false,
  negativeColor = false,
  fullWidth = false,
  required = true,
  size = "medium",
}) {
  const classes = useStyles({ negativeColor, size, fullWidth, disabled });

  const handleChange = (event, newValue) => {
    if (required && newValue === null) {
      return;
    }
    setValue(newValue);
  };

  return (
    <>
      <Box
        className={
          primaryColor
            ? classes.primaryToggleGroupWrapper
            : classes.standardToggleGroupWrapper
        }
      >
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={handleChange}
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={classes.toggleButtonGroup}
        >
          {items.map((item) => (
            <ToggleButton
              className={classes.toggleButton}
              value={item.value}
              key={item.value}
              disabled={disabled}
            >
              <Tooltip
                placement="bottom"
                title={item.tooltipText || ""}
                aria-label={`${item.text} tooltip`}
              >
                <span aria-label={item.text}>{item.text}</span>
              </Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
    </>
  );
}

export default SingleToggleGroup;
