import React, { useState, useEffect } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import IdeaRow from './IdeaRow'

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  naamcolumn: {
    maxWidth: 100,
  },
  datumcolumn: {
    maxWidth: 80,
  },
  verwijdercolumn: {
    width: 50,
    padding: 0,
  },
  inzendingcolumn: {
    maxWidth: 400,
    whiteSpace: "pre-line",
  },
});

export default function EigenIdeeInzendingen({ snackbar }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("api/creativeIdeas")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setData([]);
        setLoading(false);
        snackbar("Error tijdens het ophalen van de faq vragen", "error");
      });
  }, []);

  async function deleteAll() {
    axios
      .delete("api/creativeIdeas")
      .then((res) => {
        snackbar("Alle ideëen verwijderd!");
        setData([]);
      })
      .catch((error) => {
        snackbar("Error deleting ideëen", "error");
      });
  }


  if (loading) {
    return <div>Data aan het laden</div>;
  }

  return (
    <div id="berichtenpage">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.naamcolumn}>
                Naam
              </TableCell>
              <TableCell align="left">Inzending</TableCell>
              <TableCell align="center" className={classes.verwijdercolumn}>
                Verwijderen
                {data.length && (
                  <Button
                    onClick={() =>
                      window.confirm(
                        "Weet je zeker dat je ALLE ideëen wilt verwijderen?",
                      ) && deleteAll()
                    }
                  >
                    Verwijder alles
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <IdeaRow
                key={item._id}
                item={item}
                data={data}
                setData={setData}
                classes={classes}
                snackbar={snackbar}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
