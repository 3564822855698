import React, { useState } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageUploader from "../../common/imageUploader";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 40,
  },
});

export default function GroepToevoegen({ setData }) {
  const classes = useStyles();
  const [status, setStatus] = useState();
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [maxMembers, setMaxMembers] = useState(0);
  const [points, setPoints] = useState(0);
  const [resetImagePreview, setResetImagePreview] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newGroupData = {
      name,
      url,
      shortDescription,
      fullDescription,
      maxMembers,
      points,
    };

    try {
      const response = await axios.post("api/groups", newGroupData);

      if (response.status === 201) {
        setStatus("Groep opgeslagen!");
        setData((currentData) => [...currentData, response.data]);
        resetState();
      } else {
        setStatus("Er is een fout opgetreden bij het opslaan van de groep.");
      }
    } catch (error) {
      setStatus("Er is een fout opgetreden bij het opslaan van de groep.");
      console.error(error);
    }
  };

  const resetState = () => {
    setStatus("");
    setName("");
    setUrl("");
    setShortDescription("");
    setFullDescription("");
    setMaxMembers(0);
    setPoints(0);
    setResetImagePreview((prev) => !prev);
  };

  return (
    <Box className={classes.root}>
      <form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="groep-toevoegen-header"
            id="groep-toevoegen"
          >
            <Typography>Voeg een nieuwe groep toe</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={2}
              flexGrow={1}
            >
              <TextField
                required
                id="groepsnaam"
                value={name}
                label="Groepsnaam"
                onChange={(e) => setName(e.target.value)}
              />
              <ImageUploader
                text="Selecteer afbeelding"
                url={url}
                reset={resetImagePreview}
                setUrl={setUrl}
              />
              <TextField
                required
                id="korte-omschrijving"
                label="Korte Omschrijving"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px'}}>
                <TextField
                  required
                  id="lange-omschrijving"
                  label="Lange Omschrijving"
                  value={fullDescription}
                  inputProps={{ maxLength: 350 }}
                  multiline
                  rows={3}
                  onChange={(e) => {
                    if (e.target.value.length <= 350) {
                      setFullDescription(e.target.value);
                    }
                  }}
                />
                <Typography variant="subtitle2" sx={{ color: 'gray'}}>{ fullDescription.length } / 350</Typography>
              </Box>
              <TextField
                required
                id="max-aantal-deelnemers"
                label="Max aantal deelnemers"
                value={maxMembers}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setMaxMembers(e.target.value)}
              />
              <TextField
                id="aantal-punten"
                label="Aantal punten waard"
                value={points}
                placeholder="Kan leeggelaten worden als je geen puntensysteem gebruikt"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPoints(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !name === "" ||
                  !url === "" ||
                  !shortDescription === "" ||
                  !fullDescription === "" ||
                  !maxMembers
                }
              >
                {status || "Voeg groep toe"}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>
    </Box>
  );
}
