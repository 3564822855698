import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { styles } from "./styled";

export default function VideoComp({
  item: { id, type, pageHeader },
  updateContentItem,
}) {
  const [headerValue, setHeaderValue] = useState(pageHeader);

  const handleBlur = () => {
    updateContentItem(id, {
      id,
      type,
      pageHeader: headerValue,
    });
  };

  return (
    <Box sx={styles.wrapper}>
      <TextField
        name="pageHeader"
        value={headerValue}
        placeholder="Grote pagina header die meestal bovenaan de pagina staat"
        fullWidth
        onBlur={handleBlur}
        onChange={(event) => setHeaderValue(event.target.value)}
      />
    </Box>
  );
}
