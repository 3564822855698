import { useNavigate } from "react-router-dom";
import { Roles } from "../constants/Roles";
import userStore from "../store/userStore";
import { useEffect } from "react";

export function useRedirect(path) {
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.userInfo && path === "/") {
      navigate("/", { replace: true });
    } else if (userStore.userInfo?.role === Roles.ADMIN && path === "/admin") {
      navigate("/overview", { replace: true });
    }
  }, []);
}
