import React from "react";
import axios from "axios";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

export default function FaqRow({ item, data, setData, snackbar }) {
  const handleInputChange = (attribute) => (e) => {
    let value = e.target.value;
    let changedItem = data.findIndex((item) => item._id === e.target.name);
    let newArr = [...data];
    if (attribute === "visible") {
      if (e.target.checked) {
        value = e.target.checked;
      } else {
        value = false;
      }
    }
    newArr[changedItem][attribute] = value;
    setData(newArr);
  };

  function updateQuestion(id) {
    const newArray = [...data];
    const newArrayIndex = data.findIndex((item) => item._id === id);

    axios
      .put(`api/faq/${id}`, newArray[newArrayIndex])
      .then((res) => {
        if (res.status === 200) {
          snackbar("Vraag geüpdate!");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((error) => {
        snackbar("Error updating vraag", "error");
      });
  }

  function deleteQuestion(id) {
    axios
      .delete(`api/faq/${id}`)
      .then((res) => {
        snackbar("Vraag verwijderd!");
        const newArray = [...data];
        const newArrayIndex = data.findIndex((item) => item._id === id);
        newArray.splice(newArrayIndex, 1);
        setData(newArray);
      })
      .catch((error) => {
        snackbar("Error deleting vraag", "error");
      });
  }

  return (
    <TableRow key={item._id}>
      <TableCell component="th" scope="row">
        {item.name}
      </TableCell>
      <TableCell align="right">
        <TextField
          fullWidth={true}
          id="standard-multiline-flexible"
          label="Vraag"
          multiline
          maxRows={6}
          name={item._id}
          value={item.question}
          onChange={handleInputChange("question")}
          variant="standard"
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          fullWidth={true}
          id="standard-multiline-flexible"
          label="Antwoord"
          multiline
          maxRows={6}
          name={item._id}
          value={item.answer}
          onChange={handleInputChange("answer")}
          variant="standard"
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          checked={item.visible}
          onChange={handleInputChange("visible")}
          name={item._id}
          color="primary"
        />
      </TableCell>
      <TableCell align="center">
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            window.confirm(
              "Weet je zeker dat je deze vraag wilt verwijderen?",
            ) && deleteQuestion(item._id)
          }
        >
          Verwijder
        </Button>
      </TableCell>
      <TableCell align="center">
        <Button variant="contained" color="primary">
          <div
            className="formbutton"
            id="verstuurknop"
            onClick={() => updateQuestion(item._id)}
          >
            Update
          </div>
        </Button>
      </TableCell>
    </TableRow>
  );
}
