import React from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import Card from "@mui/material/Card";
import { Button, Box, Link, Typography } from "@mui/material";
import { useStyles } from "./FlipCard.styles";
import Paragraph from "../Paragraph";

function FlipCard(props) {
  const classes = useStyles(props);
  const {
    children,
    type,
    soldOut,
    soldText,
    title,
    list1text,
    list2text,
    list3text,
    headerBack,
    textBack,
    buttons,
  } = props;

  const flipDisabled =
    type !== "groep" && !buttons.find((button) => button.active);

  return (
    <Flippy
      flipOnHover={!flipDisabled}
      flipOnClick={false}
      flipDirection="horizontal"
      style={{ padding: 10 }}
    >
      <FrontSide style={{ padding: 0 }}>
        <Box className={classes.cardContainer}>
          {soldOut && (
            <Card className={classes.soldTextContainer}>
              <Typography className={classes.soldText}>
                {soldText.toUpperCase()}
              </Typography>
            </Card>
          )}
          <Card raised className={classes.cardFront}>
            <Box className={classes.textContainer}>
              <Typography className={classes.textSumUp}>
                {list1text && <li>{list1text}</li>}
                {list2text && <li>{list2text}</li>}
                {list3text && <li>{list3text}</li>}
              </Typography>
              <Typography className={classes.title}>
                {title.toUpperCase()}
              </Typography>
            </Box>
          </Card>
        </Box>
      </FrontSide>
      <BackSide style={{ padding: 0 }}>
        <Card raised className={classes.cardBack}>
          <Typography variant="h4">{headerBack}</Typography>
          <Paragraph
            paragraph={textBack}
            textAlign="center"
            fontSize="16px"
            mobileFontSize="3.2vw"
          />
          {children
            ? children
            : buttons.map((button) => {
                return (
                  <Button
                    key={button.key}
                    disabled={!button.active}
                    className={classes.button}
                  >
                    <Link
                      href={button.link}
                      className={classes.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {button.text}
                    </Link>
                  </Button>
                );
              })}
        </Card>
      </BackSide>
    </Flippy>
  );
}

export default FlipCard;
