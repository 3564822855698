import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import XPress from "../../../_utils/Xpress";
import ItemToevoegen from "./ItemToevoegen";
import ItemRow from "./ItemRow";
import ProgramSettings from '../settings/ProgramSettings';

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  smallColumn: {
    maxWidth: "100px",
  },
});

export default function ProgrammaBeheer({ snackbar }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [geupdate, setGeupdate] = useState(0);
  const [settings, setSettings] = useState();
  const updater = geupdate + 1;

  useEffect(() => {
    XPress.getArray("api/program").then((data) => {
      if (data) {
        setData(data);
      }
    });
  }, [geupdate]);

  useEffect(() => {
    XPress.getArray("api/settings/program").then((data) => {
      if (data) {
        setSettings(data);
      }
    });
  }, []);

  if (!data || !settings) {
    return "data aan het laden..";
  }
  return (
    <Box className={classes.root}>
      <ProgramSettings settings={settings} snackbar={snackbar}/>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Titel</TableCell>
              <TableCell align="right">Dag</TableCell>
              <TableCell align="right" className={classes.smallColumn}>
                Tijd
              </TableCell>
              <TableCell align="right">Online</TableCell>
              <TableCell align="right">Verwijderen</TableCell>
              <TableCell align="right">Aanpassen / Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .sort((a, b) => a.day - b.day || a.time - b.time)
              .map((item) => (
                <ItemRow
                  key={item._id}
                  item={item}
                  updateProp={setGeupdate}
                  updateState={updater}
                  snackbar={snackbar}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ItemToevoegen updateProp={setGeupdate} updateState={updater} snackbar={snackbar} />
    </Box>
  );
}
