import React, { useEffect, useState, useRef } from "react";
import { view } from "@risingstack/react-easy-state";
import Box from "@mui/material/Box";
import gsap from "gsap";
import "./fonts.css";
import { useStyles } from "./App.styles";
import { calcMoveTextTo, calcMoveTextFrom } from "../_utils/movementBehaviour";
import loadPageData from "../_utils/loadInitialPageData";
import DynamicComponent from "./DynamicComponent";
import DynamicContentWrapper from "./DynamicContentWrapper";
import MenuItems from "./MenuItems";
import Background from "./Background";
import InstagramLink from "./InstagramLink";
import colors from "./store";

function App() {
  const classes = useStyles();
  const [components, setComponents] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  let movingText = useRef();
  let tl = gsap.timeline();
  const movementEase = "back.out(1)";
  const duration = 2;
  const forwardDirection = prevIndex < pageIndex;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await loadPageData(setError, setComponents);
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (components.length) {
      setCurrentPage(components[0]);
    }
  }, [components]);

  useEffect(() => {
    if (components.length) {
      const backgroundState = pageIndex % 2;
      const moveTextTo = calcMoveTextTo(forwardDirection);
      const moveTextFrom = calcMoveTextFrom(forwardDirection, backgroundState);
      tl.fromTo(
        movingText,
        { x: `0` },
        {
          duration: duration,
          ease: movementEase,
          x: `${moveTextTo}vw`,
        },
      ).fromTo(
        movingText,
        { x: `${moveTextFrom}vw` },
        {
          duration: duration,
          ease: movementEase,
          x: `0`,
        },
      );
    }
  }, [pageIndex]);

  if (error) {
    return <div>there was an error loading data..</div>;
  }

  if (loading || !components.length) {
    return <div>Loading..</div>;
  }

  return (
    <>
      <InstagramLink />
      <div style={{ backgroundColor: colors.backgroundColor }}></div>
      <div style={{ backgroundColor: colors.triangleColor }}></div>
      <MenuItems
        components={components}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPageIndex={setPageIndex}
      />
      <Box className={classes.fullPage}>
        <Box
          className={classes.pageContainer}
          ref={(el) => {
            movingText = el;
          }}
        >
          <DynamicContentWrapper pageId={currentPage._id}>
            <DynamicComponent
              className={classes.page}
              currentPage={currentPage.name}
              delay={1000}
            />
          </DynamicContentWrapper>
        </Box>
      </Box>
      <Background
        index={pageIndex}
        prevIndex={prevIndex}
        setPrevIndex={setPrevIndex}
        forwardDirection={forwardDirection}
      />
    </>
  );
}

export default view(App);
