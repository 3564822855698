import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableBody } from "@mui/material";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import XPress from "../../../_utils/Xpress";
import GroepToevoegen from "./GroepToevoegen";
import Groep from "./groep/Groep";
import GroepenInstellingen from "./groep/GroepenInstellingen";
import AanmeldingenDetails from "./AanmeldingenDetails";
import { useStyles } from "./groepen.styles";

export default function GroepBeheer({ snackbar }) {
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState();
  const [details, setDetails] = useState(false);

  useEffect(() => {
    if (!data) {
      XPress.getArray("api/groups").then((data) => {
        if (data) {
          setData(data);
        }
      });
      XPress.getArray("api/settings/group").then((data) => {
        if (data) {
          setSettings(data);
        }
      });
      setLoading(false);
    }
  }, [data]);

  async function deleteAll() {
    try {
      const response = await axios.delete(`api/groups`);
      if (response.status === 204) {
        snackbar("Alle vragen verwijderd!");
        setData([]);
      }
    } catch (error) {
      snackbar("Error tijdens het verwijderen van alle groepen", "error");
      return null;
    }
  }

  if (loading) {
    return "data aan het laden..";
  }

  if (!data) {
    return "Kan de data niet laden. Refresh de pagina of probeer het later nog een keer";
  }
  return (
    <Box className={classes.root}>
      <Modal
        open={details}
        onClose={() => setDetails(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div>
          <AanmeldingenDetails setData={setData} />
        </div>
      </Modal>
      {settings ? (
        <GroepenInstellingen settings={settings} snackbar={snackbar} />
      ) : (
        <Box p={4}>
          <Typography>Kan de settings niet laden. Refresh de pagina</Typography>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Groepsnaam</TableCell>
              <TableCell align="right">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  Aanmeldingen
                  <Button onClick={() => setDetails(!details)}>Details</Button>
                </div>
              </TableCell>
              <TableCell align="right">Max Aanmeldingen</TableCell>
              <TableCell align="right" className={classes.smallColumn}>
                Punten
              </TableCell>
              <TableCell align="right">Online</TableCell>
              <TableCell align="right">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Verwijderen
                  {data.length > 0 && (
                    <Button
                      onClick={() =>
                        window.confirm(
                          "Weet je zeker dat je ALLE groepen wilt verwijderen?",
                        ) && deleteAll()
                      }
                    >
                      Verwijder alles
                    </Button>
                  )}
                </div>
              </TableCell>
              <TableCell align="right">Aanpassen / Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((group) => (
              <Groep
                key={group._id}
                group={group}
                setTotalData={setData}
                snackbar={snackbar}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <GroepToevoegen setData={setData} />
    </Box>
  );
}
