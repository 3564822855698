import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import AwesomeSlider from "react-awesome-slider";
import styles from "./ThrowBackSlider.css";

const useStyles = makeStyles({
  ImageSliderMargins: {
    margin: "5% 0 10% 0",
    ["@media (max-width:1000px)"]: {
      margin: "5% 0 20% 0",
    },
  },
});

function ThrowBackSlider({ year, numberOfImages }) {
  const classes = useStyles();

  let images = [];

  for (let i = 1; i <= numberOfImages; i++) {
    images.push(
      <div
        key={`galleryImage-${year}-${i}`}
        data-src={require(`../images/gallery${year}/karavaan${i}.jpg`)}
      />,
    );
  }

  return (
    <Box className={classes.ImageSliderMargins}>
      <AwesomeSlider className=".aws-btn" cssModule={styles}>
        {images}
      </AwesomeSlider>
    </Box>
  );
}

export default ThrowBackSlider;
