import React, { useState, useEffect } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";
import FaqRow from "./FaqRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function Faqinzendingen({ snackbar }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("api/faq")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        snackbar("Error tijdens het ophalen van de faq vragen", "error");
      });
  }, []);

  async function deleteAll() {
    axios
      .delete(`api/faq`)
      .then((res) => {
        snackbar("Alle vragen verwijderd!");
        setData([]);
      })
      .catch((error) => {
        snackbar("Error deleting vraag", "error");
      });
  }

  if (loading) {
    return <div>Data aan het laden</div>;
  }

  if (error) {
    return <div>Error bij het ophalen van de berichten..</div>;
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Naam</TableCell>
              <TableCell align="left">Vraag</TableCell>
              <TableCell align="left">Antwoord</TableCell>
              <TableCell align="center">Goedgekeurd?</TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Verwijderen
                  {data.length && (
                    <Button
                      onClick={() =>
                        window.confirm(
                          "Weet je zeker dat je ALLE vragen wilt verwijderen?",
                        ) && deleteAll()
                      }
                    >
                      Verwijder alles
                    </Button>
                  )}
                </div>
              </TableCell>
              <TableCell align="center">Updaten</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <FaqRow
                key={item._id}
                item={item}
                data={data}
                setData={setData}
                snackbar={snackbar}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
