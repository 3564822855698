import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ImageUploader from "../../../common/imageUploader";
import { useStyles } from "./groep.styles";
import Aanmeldingen from "./aanmeldingen/Aanmeldingen";
import NewMember from "./NewMember";

export default function Groep({ group, setTotalData, snackbar }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(group);
  const [fullDescriptionValueLength, setFullDescriptionValueLength] =
    useState(0);

  const buttonText = !open
    ? "Aanpassen"
    : group !== data
      ? "Update"
      : "Sluiten";

  const handleInputChange = (attribute) => (e) => {
    let value;
    switch (attribute) {
      case "url":
        value = e;
        break;
      case "online":
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }
    setData((prevState) => {
      prevState[attribute] = value;
      return {
        ...prevState,
      };
    });
  };

  async function updateGroup() {
    if (group !== data) {
      try {
        const response = await axios.put(`api/groups/${group._id}`, data);
        if (response.status === 200) {
          setTotalData((groups) =>
            groups.map((g) => (g._id === group._id ? response.data : g)),
          );
          setOpen(false);
        }
      } catch (error) {
        return snackbar(
          "Er is iets misgegaan. probeer het later nog een keer.",
          "error",
        );
      }
    }
    setOpen(false);
  }

  async function deleteGroup() {
    try {
      const response = await axios.delete(`api/groups/${group._id}`);
      if (response.status === 200) {
        setTotalData((groups) => groups.filter((g) => g._id !== group._id));
        setOpen(false);
      }
    } catch (error) {
      return snackbar(
        "Er is iets misgegaan. probeer het later nog een keer.",
        "error",
      );
    }
  }

  const deleteMemberFromGroup = async (memberId) => {
    try {
      const response = await axios.delete(
        `api/groups/${group._id}/members/${memberId}`,
      );
      if (response.status === 200) {
        setData((prevState) => {
          prevState.members = prevState.members.filter(
            (item) => item._id !== memberId,
          );
          return {
            ...prevState,
          };
        });
        return snackbar("Aanmelding verwijderd van de groep");
      }
    } catch (error) {
      return snackbar(
        "Er is iets misgegaan. probeer het later nog een keer.",
        "error",
      );
    }
  };

  return (
    <>
      <TableRow className={classes.tableRoot}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <TextField
            id={`naam-${data._id}`}
            onChange={handleInputChange("name")}
            defaultValue={data.name}
            disabled={!open}
          />
        </TableCell>
        <TableCell align="right">{data.members?.length || 0}</TableCell>
        <TableCell align="right">
          <TextField
            id={`maxMembers-${data._id}`}
            type="number"
            onChange={handleInputChange("maxMembers")}
            defaultValue={data.maxMembers}
            disabled={!open}
          />
        </TableCell>
        <TableCell align="right">
          <TextField
            id={`punten-${data._id}`}
            type="number"
            onChange={handleInputChange("points")}
            defaultValue={data.points}
            disabled={!open}
          />
        </TableCell>
        <TableCell align="right">
          <Checkbox
            checked={data.online}
            onChange={handleInputChange("online")}
            name={data._id}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
            disabled={!open}
          />
        </TableCell>
        <TableCell align="right">
          <Button
            id={`verwijderknop-${data._id}`}
            variant="contained"
            color="secondary"
            disabled={!open}
            onClick={() =>
              window.confirm(
                "Weet je zeker dat je deze groep wilt verwijderen?",
              ) && deleteGroup(data._id)
            }
          >
            Verwijder
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => (open ? updateGroup() : setOpen(true))}
          >
            {buttonText}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TableRow className={classes.tableRoot}>
                <TableCell></TableCell>
                <TableCell align="left">Afbeelding</TableCell>
                <TableCell align="left">
                  <ImageUploader
                    url={data.url}
                    text="Selecteer nieuwe afbeelding"
                    setUrl={handleInputChange("url")}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRoot}>
                <TableCell></TableCell>
                <TableCell align="left">Korte Omschrijving</TableCell>
                <TableCell align="left">
                  <TextField
                    id={`shortDescription-${data._id}`}
                    onChange={handleInputChange("shortDescription")}
                    disabled={!open}
                    fullWidth
                    multiline
                    defaultValue={data.shortDescription}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRoot}>
                <TableCell></TableCell>
                <TableCell align="left">Lange Omschrijving</TableCell>
                <TableCell align="left" className={classes.root}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <TextField
                      id={`fullDescription-${data._id}`}
                      disabled={!open}
                      multiline
                      fullWidth
                      rows={6}
                      inputProps={{ maxLength: 350 }}
                      onChange={(e) => {
                        if (e.target.value.length <= 350) {
                          setFullDescriptionValueLength(e.target.value.length);
                          handleInputChange("fullDescription", e);
                        }
                      }}
                      defaultValue={data.fullDescription}
                    />
                    <Typography variant="subtitle2" sx={{ color: "gray" }}>
                      {fullDescriptionValueLength} / 350
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <Typography variant="h6" gutterBottom>
                Aanmeldingen
              </Typography>
              <Aanmeldingen
                groupId={data._id}
                members={data.members}
                deleteMemberFromGroup={deleteMemberFromGroup}
                disabled={!open}
              />
              <NewMember
                groupId={data._id}
                setData={setData}
                snackbar={snackbar}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
