import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
  const [redirected, setRedirected] = useState(false);

  if (redirected) {
    return <Navigate to="/" />;
  }

  return (
    <div className="bg-image-notFound">
      <img
        className="intro-text-logo-notFound"
        alt="karavaan-not-found"
        src={require("../../images/KaravaanBroken.png")}
      />
      <h1 style={{ marginLeft: "4%" }}>Geen toegang tot deze pagina</h1>

      <p style={{ cursor: "pointer" }} onClick={() => setRedirected(true)}>
        Ga terug naar de homepage
      </p>
    </div>
  );
}

export default NotFound;
