import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { view } from "@risingstack/react-easy-state";
import axios from "axios";
import theme from "./components/app/theme";
import App from "./components/app/App";
import Login from "./components/login/Login";
import Overview from "./components/overview/Overview";
import NotFound from "./components/errorPage/NotFound";
import { Roles } from "./constants/Roles";
import ProtectedRoute from "./layouts/ProtectedRoute";
import userStore from "./store/userStore";
import settingsStore from "./store/settingsStore";

function Router() {
  const [credentialsLoaded, setCredentialsLoaded] = useState(false);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!userStore.userInfo) {
      const retrieveCredentials = async () => {
        try {
          const response = await axios.get("api/user/authenticate/token", {
            withCredentials: true,
          });

          if (response.status === 200) {
            userStore.userInfo = response.data.user;
          }
        } catch (error) {
          // continue
        }
        setCredentialsLoaded(true);
      };
      retrieveCredentials();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/settings/general');
        settingsStore.setGeneralSettings(response.data.settings);
      } catch (error) {
        setError(true)
      }
      setSettingsLoaded(true);
    };

    fetchData();
  }, []);

  if (!credentialsLoaded || !settingsLoaded) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Er is iets fout gegaan bij het ophalen van data. Probeer het nog een keer</div>
  }

  return (
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="router">
            <Routes>
              <Route
                element={
                  <ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.USER]} />
                }
              >
                <Route exact path="/" element={<App />} />
              </Route>
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/admin"
                element={<Login login="loginAdmin" />}
              />
              <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
                <Route path="/overview" element={<Overview />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
}
export default view(Router);
