import React, { useState, useEffect } from "react";
import XPress from "../../../_utils/Xpress";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import PaginaToevoegen from "./paginaToevoegen";
import PaginaRow from "./PaginaRow";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function PageSettings({ snackbar }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  useEffect(() => {
    XPress.getArray("api/settings/pages").then((data) => {
      setData(data);
    });
  }, []);

  if (!data) {
    return "Data aan het laden";
  }
  return (
    <Paper>
      <Typography variant="h6" gutterBottom sx={{ padding: "32px 8px 8px" }}>
        Pagina&apos;s
      </Typography>

      {data
        .sort((a, b) => {
          return a.order - b.order;
        })
        .map((item) => {
          return (
            <PaginaRow
              key={item._id}
              item={item}
              data={data}
              setData={setData}
              snackbar={snackbar}
            />
          );
        })}
      <PaginaToevoegen data={data} setData={setData} />
    </Paper>
  );
}
