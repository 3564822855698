import React, { useEffect, useState } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { nanoid } from "nanoid";

const useStyles = makeStyles({
  deleteIcon: {
    position: "absolute",
    right: 0,
    margin: "2%",
    cursor: "pointer",
  },
  deleteButton: {
    marginTop: 4,
    color: "lightgrey",
    height: 20,
    cursor: "pointer",
  },
  groepContainer: {
    width: 100,
    height: "100%",
    minHeight: 50,
    minWidth: 150,
    border: "1px solid lightgrey",
    position: "relative",
    margin: "1%",
  },
  naamWrapper: {
    display: "flex",
    justifyContent: "flex-start",
  },
});

export default function Groep({ group, groups, setGroups, snackbar }) {
  const classes = useStyles();
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showRemoveNameIcon, setShowRemoveNameIcon] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [titleValue, setTitleValue] = useState(group.title);
  const [titleEditState, setTitleEditState] = useState(false);

  useEffect(() => {
    if (group._id) {
      axios
        .put(`/api/sleepingPlan/groups/${group._id}`, group)
        .catch((error) => {
          snackbar("Er is iets misgegaan", "error");
        });
    }
  }, [group]);

  const handleChange = (_id, naam) => {
    const nameWithCapital = naam.charAt(0).toUpperCase() + naam.slice(1);
    if (nameValue !== "" && !group.names.includes(nameWithCapital)) {
      setGroups(
        groups.map((item) =>
          item._id === _id
            ? {
                _id,
                title: item.title,
                names: [...item.names, nameWithCapital],
              }
            : item,
        ),
      );
      setNameValue("");
    }
  };

  const handleTitleChange = (_id, title) => {
    if (titleValue !== "") {
      setGroups(
        groups.map((item) =>
          item._id === _id
            ? {
                _id,
                title,
                names: item.names,
              }
            : item,
        ),
      );
    }
    setTitleEditState(false);
  };

  const handleDeleteGroep = () => {
    axios
      .delete(`/api/sleepingPlan/groups/${group._id}`)
      .then((response) => {
        if (response.status === 200) {
          setGroups((prevState) =>
            prevState.filter((i) => i._id !== group._id),
          );
        } else {
          throw Error;
        }
      })
      .catch((error) => {
        snackbar("Er is iets misgegaan", "error");
      });
  };

  const handleDeleteName = (id, name) => {
    setGroups(
      groups.map((item) =>
        item._id === id
          ? {
              _id: id,
              title: item.title,
              names: item.names.filter((n) => n !== name),
            }
          : item,
      ),
    );
  };

  return (
    <Box
      className={classes.groepContainer}
      onMouseEnter={() => setShowDeleteIcon(!showDeleteIcon)}
      onMouseLeave={() => setShowDeleteIcon(!showDeleteIcon)}
    >
      {showDeleteIcon && (
        <IconButton
          size="small"
          color="primary"
          aria-label="delete"
          className={classes.deleteIcon}
          onClick={handleDeleteGroep}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {group.title === "" || titleEditState ? (
        <TextField
          label="Titel"
          size="small"
          variant="standard"
          required
          value={titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
          onBlur={() => handleTitleChange(group._id, titleValue)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleTitleChange(group._id, titleValue);
            }
          }}
        />
      ) : (
        <Typography
          variant="h6"
          style={{ cursor: "pointer" }}
          onClick={() => setTitleEditState(true)}
        >
          {group.title}
        </Typography>
      )}
      {group.names.map((n) => (
        <div
          className={classes.naamWrapper}
          key={nanoid()}
          onMouseEnter={() => setShowRemoveNameIcon(n)}
          onMouseLeave={() => setShowRemoveNameIcon("")}
        >
          <Typography>{n}</Typography>
          {showRemoveNameIcon === n && (
            <CloseIcon
              className={classes.deleteButton}
              onClick={(event) => {
                event.stopPropagation();
                handleDeleteName(group._id, n);
              }}
            />
          )}
        </div>
      ))}
      <TextField
        label="Voeg persoon toe"
        size="small"
        variant="standard"
        value={nameValue}
        onChange={(e) => setNameValue(e.target.value)}
        onBlur={() => handleChange(group._id, nameValue)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleChange(group._id, nameValue);
          }
        }}
      />
    </Box>
  );
}
