export const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  player: {
    display: 'flex',
    position: "absolute",
  },
  button: {
    width: '75px',
    height: '75px',
    color: 'red',
    cursor: 'pointer',
  },
};
