import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { view } from "@risingstack/react-easy-state";
import { Global } from "@emotion/react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "./InschrijfBalk.styled";
import groupStore from "../../store/groupStore";
import settingsStore from "../../store/settingsStore";
import InschrijfModal from "./InschrijfModal";

const drawerBleeding = -56;

const InschrijfBalk = view(() => {
  const [open, setOpen] = useState(false);
  const { puntenIngezet, aanmeldingen } = groupStore;
  const classes = useStyles({
    drawerBleeding,
  });

  const buttonText = () => {
    if (!settingsStore.groups.online) {
      return "Inschrijven is momenteel niet mogelijk";
    }
    if (settingsStore.groups.useChips) {
      if (puntenIngezet === 1) {
        return "Je hebt 1 punt ingezet";
      } else {
        return `Je hebt ${puntenIngezet} punten ingezet`;
      }
    } else if (aanmeldingen.length === 0) {
      return "Selecteer eerst een groep";
    } else {
      return " Voltooi je aanmelding hier";
    }
  };

  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: isMobile
              ? `calc(90% - ${drawerBleeding}px)`
              : `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          className={classes.inschrijfBalk}
          variant="outlined"
          onClick={() => settingsStore.groups.online && setOpen(true)}
        >
          <Typography sx={{ py: 2, px: "2%" }}>{buttonText()}</Typography>
        </Box>
        <Box className={classes.inschrijfModal}>
          <InschrijfModal />
        </Box>
      </Drawer>
    </>
  );
});

export default InschrijfBalk;
