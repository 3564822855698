import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { view } from "@risingstack/react-easy-state";
import settingsStore from "../../store/settingsStore";
import { isMobile } from "react-device-detect";
import UserLogin from "./user/UserLogin";
import AdminLogin from "./admin/AdminLogin";
import { useStyles } from "./Login.styles";

const components = {
  login: UserLogin,
  loginAdmin: AdminLogin,
};

function Login({ login }) {
  const { theme } = settingsStore.general;
  const [selectedComponent, setSelectedComponent] = useState(null);
  const SelectComponent = components[selectedComponent];
  const classes = useStyles();
  let movingImg = useRef();
  let tl = gsap.timeline();

  useEffect(() => {
    if (login === "loginAdmin") {
      setSelectedComponent("loginAdmin");
    } else {
      setSelectedComponent("login");
    }
    if (theme === "winter") {
      tl.fromTo(
        movingImg,
        { y: `20vw` },
        {
          duration: 2,
          ease: "power3.out",
          y: 0,
        },
      ).delay(1);
    }
  }, []);

  return (
    <div className={classes.bgImage}>
      <img
        className={classes.introTextLogo}
        src={require("../../images/KaravaanIntro.jpg")}
      />

      {selectedComponent && (
        <div style={{ zIndex: 10, width: "35%" }}>
          <SelectComponent />
        </div>
      )}
      {theme === "winter" && (
        <div className={classes.treesContainer}>
          <img
            ref={(el) => {
              movingImg = el;
            }}
            className={classes.trees}
            src={
              isMobile
                ? require("../../images/ChateauKaravaanBomenMobile.png")
                : require("../../images/ChateauKaravaanBomen.png")
            }
          />
        </div>
      )}
    </div>
  );
}

export default view(Login);
