import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  smallColumn: {
    maxWidth: "100px",
  },
});
