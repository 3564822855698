import React, { useEffect, useState } from "react";
import axios from "axios";
import CardList from "../../common/CardList";
import { useStyles } from "./Bootindeling.styles";

export function Bootindeling() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [groepen, setGroepen] = useState([]);

  useEffect(() => {
    axios
      .get("api/boatPlan/visible")
      .then((response) => {
        setGroepen(response.data)
      })
      .catch((error) => {
        setError(true)
      });
      setLoading(false)
  }, []);;

  if (loading) {
    return (<div>Data aan het laden...</div>)
  }

  if (error) {
    return (<div>Kon de data niet ophalen.. probeer het later nog een keer</div>)
  }
  return (
    <>
      <div className={classes.wideContainer}>
        <div className={classes.root}>
          {groepen.map(({ id, title, names }, index) => {
            return (
              <CardList
                key={`${index}-${id}`}
                id={id}
                title={title}
                content={names}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
