import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(20),
      minHeight: theme.spacing(25),
      paddingBottom: theme.spacing(1),
    },
    justifyContent: "center",
    marginTop: 50,
  },
  wideContainer: {
    ...theme.wideContainer,
  },
}));
