import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import zwart200px from "../images/zwart200px.png";
import { randomBackgroundPosition } from "../components/_utils/randomBackgroundPosition";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "0.5% 0",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  },
  headerColoredTopBorder: {
    backgroundImage: ({ imageIndex }) =>
      `url(${theme.topbarImage[imageIndex]})`,
    backgroundSize: "cover",
    height: "5px",
    width: "100%",
  },
  header: {
    backgroundImage: `url(${zwart200px})`,
    backgroundPosition: ({ backgroundPos }) => backgroundPos,
    backgroundSize: "cover",
  },
  title: {
    fontFamily: "Cairo, sans-serif",
    fontWeight: "bold",
    color: "white",
    fontSize: "1.5em",
    lineHeight: "1.1",
    textAlign: "right",
    marginRight: "5%",
  },
  content: {
    fontSize: "1.05em",
    color: "white",
    paddingLeft: "10px",
    listStyleType: "none",
    lineHeight: "1.4em",
  },
}));

export default function CardList({ id, title, content, index }) {
  const imageIndex = index % 11;
  const [backgroundPos, setBackgroundPos] = useState();
  const classes = useStyles({ imageIndex, backgroundPos });

  useEffect(() => {
    setBackgroundPos(randomBackgroundPosition);
  }, []);

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.headerColoredTopBorder}></div>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {title.toUpperCase()}
          </Typography>
        </div>
        {content?.map((item) => {
          return (
            <Typography key={id + item} className={classes.content}>
              {item}
            </Typography>
          );
        })}
      </Paper>
    </>
  );
}
