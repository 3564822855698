import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import userStore from "../../../store/userStore";
import "./userLogin.css";
import { useRedirect } from "../../../hooks/useRedirect";

function UserLogin() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [showLoginPart, setShowLoginPart] = useState("Hidden");
  useRedirect("/");

  function toggleLogin() {
    var css = showLoginPart === "Hidden" ? "Show" : "Hidden";
    setShowLoginPart(css);
  }

  async function postLogin(e) {
    e.preventDefault();

    try {
      await axios
        .post(
          "api/user/authenticate/login/user",
          { password },
          { withCredentials: true },
        )
        .then((res) => {
          if (res.status === 200) {
            userStore.setUserInfo(res.data.userInfo);
            navigate("/", { replace: true });
          }
        });
    } catch (err) {
      setPassword("");
      setError(err?.response?.data?.error || "Server error");
    }
  }

  return (
    <div>
      <div className="Card">
        <img
          className="intro-logo"
          src={require("../../../images/LOGO_INLOG.png")}
        />
        <div className="LoginButton" onClick={toggleLogin}>
          LOGIN
        </div>
        <div className={showLoginPart} id="loginInput">
          <form className="Form" onSubmit={postLogin}>
            <input
              className="Input"
              type="password"
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Wachtwoord"
            />
            <button type="submit" className="Button">
              Login
            </button>
            {error && <div className="error-message">{error}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
