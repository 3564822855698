import React from "react";
import GeneralSettings from "./general/GeneralSettings";
import PageSettings from "./pages/PageSettings";

export default function Settings({ snackbar }) {
  return (
    <>
      <GeneralSettings snackbar={snackbar} />
      <PageSettings snackbar={snackbar} />
    </>
  );
}
