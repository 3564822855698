import React, { useState } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useStyles } from "./GeneralSettings.styles";
import { Select, MenuItem, InputLabel } from "@mui/material";
import settingsStore from "../../../../store/settingsStore";

export default function GeneralSettings({ snackbar }) {
  const [userPassword, setUserPassword] = useState("");
  const [theme, setTheme] = useState(settingsStore.general.theme ?? "");

  const handleThemeChange = async (event) => {
    const newTheme = event.target.value;

    try {
      setTheme(newTheme);
      await axios.put("/api/settings/general", { newTheme });
    } catch (error) {
      snackbar(
        "Er is iets mis gegaan tijdens het aanpassen van het thema",
        "error",
      );
    }
  };

  const handleChangePassword = async () => {
    try {
      await axios.put(`api/user/change-login-password`, {
        newPassword: userPassword,
      });
      setUserPassword("");
      snackbar("Wachtwoord aangepast");
    } catch (error) {
      snackbar(
        "Er is iets mis gegaan tijdens het aanpassen van het wachtwoord",
        "error",
      );
    }
  };
  const classes = useStyles();

  return (
    <Paper className={classes.settingsWrapper}>
      <Typography variant="h6" gutterBottom>
        Algemeen
      </Typography>
      <div>
        <div className={classes.settingsFormContainer}>
          <div className={classes.inputContainer}>
            <span>Wachtwoord voor toegang website</span>
            <TextField
              id="newMember-name"
              value={userPassword}
              placeholder="wachtwoord"
              onChange={(event) => {
                setUserPassword(event.target.value);
              }}
              variant="standard"
            />
          </div>
          <Button
            variant="standard"
            disabled={userPassword === ""}
            onClick={() =>
              window.confirm(
                "Weet je zeker dat je het wachtwoord voor de website wilt aanpassen?",
              ) && handleChangePassword()
            }
          >
            opslaan
          </Button>
        </div>
      </div>
      <div>
        <InputLabel id="theme-select">Site Thema</InputLabel>
        <Select
          labelId="theme-select"
          id="theme-select"
          value={theme}
          label="Thema"
          onChange={handleThemeChange}
        >
          <MenuItem value="summer">Zomer</MenuItem>
          <MenuItem value="winter">Winter</MenuItem>
        </Select>
      </div>
    </Paper>
  );
}
