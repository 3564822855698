import axios from "axios";

const loadPageData = async (setError, setComponents) => {
  try {
    const response = await axios.get("api/settings/pages/visible");
    const data = response.data;
    if (data.length) {
      setComponents(data);
    } else {
      setError(true);
    }
  } catch (error) {
    setError(true);
  }
};

export default loadPageData;
