import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import userStore from "../../../store/userStore";
import "./adminLogin.css";
import { useRedirect } from "../../../hooks/useRedirect";

function AdminLogin() {
  useRedirect("/admin");
  const navigate = useNavigate();
  const [state, setState] = useState("idle");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const { username, password } = data;

  function handleChange(e) {
    const { name, value } = e.target;
    setData((prevState) => {
      prevState[name] = value;
      return {
        ...prevState,
      };
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (!username || !password) {
      return;
    }
    setState("Loading");

    try {
      await axios
        .post(
          "api/user/authenticate/login/admin",
          { username, password },
          { withCredentials: true },
        )
        .then((res) => {
          if (res.status === 200) {
            userStore.setUserInfo(res.data.userInfo);
            navigate("/overview", { replace: true });
          }
        });
    } catch (err) {
      setError(err.response.data.error);
    }
  }

  return (
    <div className="Login">
      <form name="form" onSubmit={handleSubmit}>
        <div className="inputContainer">
          <label htmlFor="username">
            <p>Gebruikersnaam</p>
          </label>
          <input
            type="text"
            className="form-control-input"
            name="username"
            value={username}
            onChange={handleChange}
          />
          {submitted && !username && (
            <div className="error-message">
              <p>Gebruikersnaam is niet ingevuld</p>
            </div>
          )}
          <label htmlFor="password">
            <p>Wachtwoord</p>
          </label>
          <input
            type="password"
            className="form-control-input"
            name="password"
            value={password}
            onChange={handleChange}
          />
          {submitted && !password && (
            <div className="error-message">
              <p>Wachtwoord is niet ingevuld</p>
            </div>
          )}
          <div className="form-group" id="login">
            <button
              className="Button"
              id="loginButton"
              disabled={state === "loading"}
            >
              Login
            </button>
            {state === "loading" && (
              <img
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                alt=""
              />
            )}
          </div>
          {error && username && password && (
            <div className="error-message">{error}</div>
          )}
        </div>
      </form>
    </div>
  );
}

export default view(AdminLogin);
