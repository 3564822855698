import React, { useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import "../formulier/Formulier.css";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
  inputField: {
    marginBottom: 15,
  },

  ["@media (max-width:500px)"]: {
    menuItem: {
      fontSize: "0.8rem",
      whiteSpace: "unset",
      wordBreak: "break-all",
    },
  },
}));

const validUrlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

export function BorgTerug() {
  const classes = useStyles();
  const ref = useRef({});
  const [status, setStatus] = useState();
  const [data, setData] = useState({
    voornaam: "",
    achternaam: "",
    link: "vega",
  });
  const [url, setUrl] = useState("");
  const [error, setError] = useState(false);

  const handleUrlChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);
  };
  const validateUrl = () => {
    if (validUrlRegex.test(url)) {
      setError(false);
      setData({
        ...data,
        link: url,
      });
    } else {
      setError(true);
    }
  };

  const handleInputChange = (attribute) => (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [attribute]: value,
    });
  };

  async function vraagVerzenden(e) {
    e.preventDefault();
    fetch("/overview/berichten/formulier", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    }).then((res) => {
      if (res.status === 201) {
        setStatus("We hebben 'm ontvangen!");
      } else {
        const error = new Error(res.error);
        console.log(error);
        setStatus(
          "⁉️🤖 Er is iets misgegaan! Refresh de pagina en probeer het opnieuw, probeer anders een andere browser of probeer het op een ander apparaat.",
        );
      }
    });
  }
  if (status) {
    return <Box mt={3}>{status}</Box>;
  }
  return (
    <form onSubmit={(e) => vraagVerzenden(e)} autoComplete="off">
      <FormControl
        fullWidth
        className={classes.form}
        variant="filled"
        margin="dense"
      >
        <TextField
          required
          className={classes.inputField}
          id="voornaam"
          label="Voornaam"
          inputRef={(el) => (ref.current["voornaam"] = el)}
          onBlur={handleInputChange("voornaam")}
        />
        <TextField
          required
          className={classes.inputField}
          id="achternaam"
          label="Achternaam"
          inputRef={(el) => (ref.current["achternaam"] = el)}
          onBlur={handleInputChange("achternaam")}
        />
        <TextField
          required
          className={classes.inputField}
          id="link"
          label="Betaalverzoek link"
          inputRef={(el) => (ref.current["link"] = el)}
          onChange={handleUrlChange}
          error={error}
          helperText={error ? "Je kunt alleen een geldige url invoeren" : ""}
          onBlur={validateUrl}
        />

        <div className="buttondiv">
          <Button type="submit" className={classes.button} disabled={error}>
            Verstuur
          </Button>
        </div>
      </FormControl>
    </form>
  );
}
