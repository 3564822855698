import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import FlipCard from "../../common/flipCard/FlipCard";
import tentTicket from "../../images/tickets/tentTicket.png";
import veldTicket from "../../images/tickets/veldTicket.png";
import theme from "../app/theme";

const useStyles = makeStyles({
  wideContainer: {
    ...theme.wideContainer,
  },
});

export function Betaling() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wideContainer}>
        <FlipCard
          backgroundImg={tentTicket}
          soldText="Uitverkocht"
          soldOut
          title="kluut tent"
          list1text="/\ Vooropgezette tent"
          list2text="/\ Stroom"
          list3text="/\ Meerdere personen"
          textBack="LET OP: Alleen voor mensen die al een aanbetaling voor dit type ticket gedaan hebben. Voldoe de tweede termijn van €105,- voor 1 maart. "
          buttons={[
            {
              key: "i023ojf34ifihowekajfaioefj4f4qacrqg43",
              active: false,
              link: "..",
              text: "Betaal een termijn",
            },
            {
              key: "i023ojf34ifihowef23gf2wefsafsfsd4qacrqg43",
              active: false,
              link: "..",
              text: "Betaal het gehele bedrag",
            },
          ]}
        />
        <FlipCard
          backgroundImg={veldTicket}
          soldText="Uitverkocht"
          soldOut
          title="byo tent"
          list1text="/\ Eigen tent"
          list2text="/\ Geen stroom"
          list3text="/\ Alleen 2p tenten"
          textBack="Het totale bedrag is €175,-. Voldoe het volledige bedrag voor 1 maart of betaal in twee termijnen en voldoe de eerste termijn (€ 87,50) voor 1 maart en de tweede voor 1 april. Het enige wat je hoeft mee te nemen is een tweepersoonstent en slaapspullen. Let op: we mogen maximaal 10 tweepersoonstenten opzetten op het eiland. Dit betekent dat in elke tent ook 2 personen liggen. Mocht je nog geen tentbuddy hebben of twijfelen over de grootte van je tent: neem dan even contact ons op. "
          buttons={[
            {
              key: "2043ig3wigeirijgri",
              active: false,
              link: "..",
              text: "Betaal een termijn",
            },
            {
              key: "2i04jfi2if3i24igiegdg",
              active: false,
              link: "..",
              text: "Betaal het gehele bedrag",
            },
          ]}
        />
      </div>
    </>
  );
}
