import "whatwg-fetch";

const XPress = {};
const baseUrl = window.location.origin;

XPress.getArray = (path) => {
  const url = `${baseUrl}/${path}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve([]));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

XPress.getItem = (path, id) => {
  const url = `${baseUrl}/${path}/${id}`;
  return fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve([]));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

//UPDATE ITEM
XPress.updateItem = (path, item) => {
  const id = item._id;
  const url = `${baseUrl}/${path}/${id}`;
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ item }),
  };
  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return response;
  });
};

XPress.deleteItem = (path, id) => {
  const completeUrl = `${baseUrl}/${path}/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(completeUrl, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

XPress.deleteItems = (path) => {
  const completeUrl = `${baseUrl}/${path}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(completeUrl, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

/// ------------------ ///

XPress.deleteAanmelding = (id) => {
  // DEZE KAN VERWIJDERD WORDEN, EERST IMPLEMENTEREN
  const url = `${baseUrl}/overview/taken/:taak/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(url, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

//UPDATE VRAAG UIT BRIEVENBUS
XPress.updateVraag = (item) => {
  const id = item._id;
  const url = `${baseUrl}/overview/berichten/faqinzendingen/${id}`;
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ item: item }),
  };
  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return response;
  });
};

//DELETE VRAAG UIT BRIEVENBUS
XPress.deleteVraag = (id) => {
  const url = `${baseUrl}/overview/berichten/faqinzendingen/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(url, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

//  EIGEN IDEE INZENDINGEN  //

//DELETE INZENDING EIGEN IDEE
XPress.deleteInzendingEigenIdee = (id) => {
  const url = `${baseUrl}/overview/eigenIdee/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(url, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp.json().then((jsonResponse) => {
      return jsonResponse;
    });
  });
};

//UPDATE GROEP
XPress.updateGroep = (item) => {
  const id = item._id;
  const url = `${baseUrl}/overview/groepen/${id}`;
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ item }),
  };
  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return response;
  });
};

//DELETE GROEP
XPress.deleteGroep = (id) => {
  const url = `${baseUrl}/overview/groepen/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(url, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp;
  });
};

// ADD DEELNEMER TO GROEP
XPress.addDeelnemerToGroep = (aanmelding) => {
  const groepsId = aanmelding.groepId;
  const url = `${baseUrl}/overview/groepen/aanmelding/${groepsId}`;
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ aanmelding }),
  };
  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return response;
  });
};

//DELETE DEELNEMER FROM GROEP
XPress.deleteDeelnemer = (params) => {
  const url = `${baseUrl}/overview/groepen/aanmelding/delete/deelnemer/${params.groepId}`;
  const fetchOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ params }),
  };
  return fetch(url, fetchOptions).then((response) => {
    if (!response.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return response;
  });
};

//DELETE ITEM
XPress.deleteFormulierInzending = (id) => {
  const url = `${baseUrl}/overview/berichten/formulier/${id}`;
  const fetchOptions = {
    method: "DELETE",
  };
  return fetch(url, fetchOptions).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve(null));
    }
    return resp;
  });
};

XPress.getOverview = (tokenName, token) => {
  const url = `${baseUrl}/overview`;
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tokenName, token }),
  }).then((resp) => {
    if (!resp.ok) {
      return new Promise((resolve) => resolve([]));
    }
    return resp.status;
  });
};

export default XPress;
