import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  inschrijfBalk: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    position: "absolute",
    top: ({ drawerBleeding }) => drawerBleeding,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottom: "1px solid darkgrey",
    visibility: "visible",
    transition: "all .5s ease-out",
    right: 0,
    left: 0,
  },
  inschrijfModal: {
    height: "100%",
    overflow: "auto",
    padding: "16px 5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
