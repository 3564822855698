import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  settingsWrapper: {
    padding: "32px 8px",
    display: "flex",
    flexDirection: "column",
    gap: '10px',
  },
  settingsFormContainer: {
    display: "flex",
    gap: "8px",
  },

  inputContainer: {
    display: "flex",
    flexDirection: "column",
  },
});
