import React from "react";
import axios from "axios";
import { TableRow, TableCell, Button } from "@material-ui/core";

const IdeaRow = ({ item, classes, data, setData, snackbar }) => {
  const deleteIdea = async (id) => {
    try {
      await axios.delete(`/api/creativeIdeas/${id}`);
      snackbar("idee verwijderd!");
      const newArray = data.filter((item) => item._id !== id);
      setData(newArray);
    } catch (error) {
        console.log(error)
      snackbar("Error deleting idee", "error");
    }
  };

  const handleDeleteClick = async () => {
    if (window.confirm("Weet je zeker dat je dit idee wilt verwijderen?")) {
      await deleteIdea(item._id);
    }
  };

  return (
    <TableRow key={item._id}>
      <TableCell component="th" scope="row" className={classes.naamcolumn}>
        {item.name}
      </TableCell>
      <TableCell component="th" scope="row" className={classes.inzendingcolumn}>
        {item.idea}
      </TableCell>
      <TableCell align="center">
        <Button variant="contained" color="secondary" onClick={handleDeleteClick}>
          Verwijder
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default IdeaRow;