import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  groepBox: {
    width: 100,
    height: 100,
    border: "2px solid black",
    backgroundColor: "lightgray",
  },
  column: {
    width: "33%",
  },
});
