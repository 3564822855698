import React from "react";
import { Box, TextField } from "@mui/material";
import { styles } from "./styled";
import { useState } from "react";

export default function ParagraphComp({
  item: { id, type, header, text },
  updateContentItem,
}) {
  const [headerValue, setHeaderValue] = useState(header);
  const [textValue, setTextValue] = useState(text);

  const handleBlur = () => {
    updateContentItem(id, {
      id,
      type,
      header: headerValue,
      text: textValue,
    });
  };

  return (
    <Box sx={styles.wrapper}>
      <TextField
        name="header"
        value={headerValue}
        placeholder="Voeg een kop boven de tekst toe (optioneel)"
        fullWidth
        onBlur={handleBlur}
        onChange={(event) => setHeaderValue(event.target.value)}
      />
      <TextField
        name="text"
        value={textValue}
        placeholder="Voeg tekst toe"
        required
        variant="outlined"
        multiline
        rows="6"
        fullWidth
        onBlur={handleBlur}
        onChange={(event) => setTextValue(event.target.value)}
      />
    </Box>
  );
}
