import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "center",
    width: "75vw",
    maxWidth: 370,
    height: "90vw",
    maxHeight: 438,
  },
  cardFront: {
    display: "flex",
    alignItems: "flex-end",
    backgroundImage: (props) => `url(${props.backgroundImg})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100%",
  },
  cardBack: {
    display: "flex",
    backgroundColor: "#b4cfff",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "7%",
    width: "100%",
    height: "100%",
  },
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "90%",
    paddingLeft: "10%",
    marginBottom: "2%",
    ["@media (max-width:500px)"]: {
      width: "92%",
    },
  },
  textSumUp: {
    fontSize: "1.05em",
    color: "white",
    listStyleType: "none",
    lineHeight: "1.4em",
    whiteSpace: "nowrap",
    ["@media (max-width:500px)"]: {
      fontSize: "3.8vw",
    },
  },
  title: {
    fontFamily: "Cairo, sans-serif",
    fontWeight: "bold",
    color: "white",
    fontSize: "1.9em",
    lineHeight: "1.1",
    textAlign: "right",
    ["@media (max-width:500px)"]: {
      fontSize: "7.6vw",
    },
  },
  button: {
    ...theme.button,
    backgroundColor: "rgb(255, 230, 254)",
    border: "1px solid rgb(255 226 255)",
    "&:hover": {
      backgroundColor: "rgb(253 244 253)",
    },
    "& .Mui-disabled": {
      opacity: 0.25,
    },
  },
  link: {
    textDecoration: "none",
    color: "rgb(6, 40, 70)",
    ["@media (max-width:500px)"]: {
      fontSize: "2.8vw",
    },
  },
  soldTextContainer: {
    position: "absolute",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    height: "73%",
    width: "85%",
    marginTop: "9.5%",
    marginRight: "0.5%",
    backgroundImage: (props) => `url(${props.backgroundImg})`,
    backgroundBlendMode: "luminosity",
    backgroundPosition: "center",
    backgroundSize: "121%",
    backgroundPositionY: "31%",
    borderRadius: 0,
  },
  soldText: {
    position: "absolute",
    color: "yellow",
    fontWeight: "bold",
    fontSize: "1.7em",
    textAlign: "center",
    textShadow: "-2px 2px 0px rgba(255,0,0,1)",
    ["@media (max-width:500px)"]: {
      fontSize: "6.3vw",
    },
  },
}));
