import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  img: {
    maxWidth: "100%",
  },
  imgDiv: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    height: "100%",
  },
  imgTxt: {
    position: "absolute",
    margin: "1% 2%",
  },
});
