import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  toggleButton: {
    fontWeight: 500,
    height: ({ size }) => (size === "small" ? 32 : 40),
    flex: ({ fullWidth }) => fullWidth && 1,
  },
  primaryToggleGroupWrapper: {
    "& .Mui-selected": {
      color: "white",
      backgroundColor: ({ disabled }) =>
        disabled ? "grey" : "rgb(25, 118, 210)",
      fontWeight: 400,
      "&:hover": {
        backgroundColor: "#0783fe",
      },
    },
    "& .Mui-selected:last-child": {
      backgroundColor: ({ negativeColor, disabled }) =>
        negativeColor && !disabled && "#e14b4b",
      "&:hover": {
        backgroundColor: "rgb(245 36 36)",
      },
    },
  },
  standardToggleGroupWrapper: {
    "& .Mui-selected": {
      backgroundColor: "gray",
      color: "rgb(0, 0, 0, 0.87)",
    },
    "& + &": {
      marginLeft: 15,
    },
  },
  toggleButtonGroup: {
    background: "#fff",
    width: "100%",
  },
});
