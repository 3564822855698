import React, { useEffect, useState } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px",
  },
  modalFlexPosition: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignmembers: "center",
    top: "50%",
    left: "50%",
    width: "80vw",
    position: "absolute",
    transform: `translate(-50%, -50%)`,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    maxHeight: "90vh",
    padding: theme.spacing(2, 4, 3),
    backgroundColor: "white",
  },
}));
export default function AanmeldingenDetails({ setData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMemberData = async () => {
      try {
        const response = await axios.get("api/groups/members/all");
        setMembers(response.data);
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    };

    fetchMemberData();
  }, []);

  const deleteAllMembers = async () => {
    try {
      const response = await axios.delete("api/groups/members/all");
      setData(response.groups);
      setMembers([]);
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  if (loading) {
    return <div className={classes.modalFlexPosition}>Laden...</div>;
  }
  if (error) {
    return (
      <div className={classes.modalFlexPosition}>
        Error bij het ophalen van de data
      </div>
    );
  }
  if (!members.length) {
    return (
      <div className={classes.modalFlexPosition}>
        <Typography component={"span"} variant="body1">
          Er zijn nog geen aanmeldingen
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.modalFlexPosition}>
      <div className={classes.modalHeader}>
        <Typography type="span">
          Totaal aantal aanmeldingen: {members.length}
        </Typography>
        {members.length > 0 && (
          <Button
            onClick={() =>
              window.confirm(
                "Weet je zeker dat je ALLE aanmeldingen uit ALLE groepen wilt verwijderen?",
              ) && deleteAllMembers()
            }
          >
            Verwijder alle aanmeldingen
          </Button>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableBody>
            {members.map((member) => (
              <TableRow key={member.name}>
                <TableCell align="right">{member.name}</TableCell>
                <TableCell align="right">{member.groups.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
