import React, { useRef, useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { view } from "@risingstack/react-easy-state";
import groupStore from "../../store/groupStore";
import settingsStore from "../../store/settingsStore";
import { useStyles } from "./InschrijfModal.styled";

const InschrijfModal = view(() => {
  const [error, setError] = useState();
  const classes = useStyles();
  const ref = useRef({});
  const {
    aanmeldingen,
    zijnErAanmeldingen,
    userInfo,
    puntenIngezet,
    aanmeldingVerstuurd,
    setAangemeld,
    volleGroep,
  } = groupStore;
  const { useChips } = settingsStore.groups;

  const handleInputChange =
    (attribute) =>
    ({ target }) => {
      let newArr = groupStore.userInfo;
      newArr[attribute] = target.value;
      groupStore.userInfo = newArr;
    };

  const setCookie = () => {
    let expiryDate = new Date(Date.now() + 6.048e8 * 2);
    document.cookie = `aangemeldCookie; expires=` + expiryDate;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    for (const groep of aanmeldingen) {
      try {
        await axios.get(`api/groups/${groep._id}/members/validate`);
      } catch (error) {
        groupStore.addGroepToBezet(groep._id);
      }
    }

    if (!volleGroep.length) {
      for (const groep of aanmeldingen) {
        const aanmelding = {
          name: userInfo.voornaam,
          surname: userInfo.achternaam,
          motivation: userInfo.motivatie,
        };

        try {
          const response = await axios.put(
            `api/groups/${groep._id}/members`,
            aanmelding,
          );
          setAangemeld();
          setCookie();
          return response.data;
        } catch (error) {
          setError(
            `Er is iets fout gegaan. Je aanmelding voor ${groep.name} is niet opgeslagen. Refresh de pagina, probeer het opnieuw en neem contact met ons op als het nog blijft aanhouden.`,
          );
        }
      }
    }
  }

  const text = () => {
    if (useChips) {
      if (puntenIngezet === 0) {
        return "Je hebt nog geen groep geselecteerd, verdeel 2 punten om je aan te kunnen melden";
      } else if (puntenIngezet === 1) {
        return "Je moet nog 1 punt inzetten om je aan te kunnen melden";
      } else if (puntenIngezet === 2) {
        return "Je hebt 2 punten ingezet, je kan je dus aanmelden";
      }
      return "Je hebt teveel punten ingezet. Alleen met 2 punten kan je je aanmelden";
    } else {
      if (aanmeldingen.length === 0) {
        return "Je hebt nog geen groep geselecteerd, selecteer een groep om je aan te kunnen melden";
      } else if (aanmeldingen.length === 1) {
        return "";
      }
      return "Je hebt teveel groepen geselecteerd. Je kan je slechts bij één groep aanmelden";
    }
  };

  if (error) {
    return (
      <Typography component={"span"} variant="body1">
        {error}
      </Typography>
    );
  }

  if (aanmeldingVerstuurd) {
    return (
      <Typography component={"span"} variant="body1">
        Bedankt voor je aanmelding! Meer info volgt snel
      </Typography>
    );
  }

  if (document.cookie.indexOf("aangemeldCookie") !== -1) {
    return (
      <Typography component={"span"} variant="body1">
        Je hebt je al aangemeld. Mocht dit niet het geval zijn maar zie je toch
        deze pagina, neem dan even contact met ons op via de brievenbus.
      </Typography>
    );
  }

  return (
    <>
      {zijnErAanmeldingen && (
        <Typography component={"span"} variant="body1">
          Jouw keuze:
          {aanmeldingen.map((item) => (
            <div
              key={item._id}
              className={
                volleGroep.includes(item._id) ? classes.volleGroep : null
              }
            >
              <div className={classes.groepnaamContainer}>
                {item.name}{" "}
                {useChips &&
                  `(${item.points} 
                ${item.points === 1 ? "punt" : "punten"})`}
                <IconButton
                  aria-label="delete"
                  onClick={() => groupStore.remove(item)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
              {volleGroep.includes(item._id) ? (
                <div className={classes.errorText}>
                  Deze groep zit helaas al vol, refresh de pagina voor de juiste
                  data
                </div>
              ) : null}
            </div>
          ))}
        </Typography>
      )}
      <form className={classes.form} onSubmit={handleSubmit} autoComplete="off">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flexGrow={1}
        >
          <TextField
            required
            id="voornaam"
            label="voornaam"
            defaultValue={userInfo.voornaam || ""}
            inputRef={(el) => (ref.current["voornaam"] = el)}
            onBlur={handleInputChange("voornaam")}
          />
          <TextField
            required
            id="achternaam"
            label="achternaam"
            defaultValue={userInfo.achternaam || ""}
            inputRef={(el) => (ref.current["achternaam"] = el)}
            onBlur={handleInputChange("achternaam")}
          />
          <TextField
            required
            id="motivatie"
            label="Wat denk jij toe te voegen aan jouw groepje?"
            defaultValue={userInfo.motivatie || ""}
            multiline
            rows={4}
            inputRef={(el) => (ref.current["motivatie"] = el)}
            onBlur={handleInputChange("motivatie")}
          />
        </Box>
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            className={classes.button}
            disabled={useChips && puntenIngezet !== 2}
          >
            Aanmelden
          </Button>
          <Typography variant="body1">{text()}</Typography>
        </div>
      </form>
    </>
  );
});

export default InschrijfModal;
