import React, { useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useStyles } from "./NewMember.styles";

export default function NewMember({ groupId, setData, snackbar }) {
  const classes = useStyles();
  const [member, setMember] = useState({
    name: "",
    surname: "",
    motivation: "",
  });

  const handleInputChange = (attribute) => (e) => {
    setMember((prevState) => {
      prevState[attribute] = e.target.value;
      return {
        ...prevState,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.put(`api/groups/${groupId}/members`, member);
      setData((group) => {
        const updatedMembers = [...group.members, response.data.member];
        setMember({
          name: "",
          surname: "",
          motivation: "",
        });
        return {
          ...group,
          members: updatedMembers,
        };
      });
      return snackbar("Deelnemer toegevoegd aan de groep");
    } catch (error) {
      snackbar(
        "Er is iets fout gegaa tijdens het opslaan van de deelnemer.",
        "error",
      );
    }
  };

  return (
    <div className={classes.container}>
      Voeg een aanmelding toe aan deze groep
      <div className={classes.newMemberFormContainer}>
        <TextField
          id="newMember-name"
          placeholder="voornaam"
          value={member.name}
          onChange={handleInputChange("name")}
          variant="standard"
        />
        <TextField
          id="newMember-surname"
          placeholder="achternaam"
          value={member.surname}
          onChange={handleInputChange("surname")}
          variant="standard"
        />
        <IconButton
          aria-label="expand row"
          size="small"
          disabled={member.name === "" || member.surname === ""}
          onClick={handleSubmit}
        >
          <AddOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}
