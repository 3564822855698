import React from 'react';
import { Box } from "@mui/material";

export default function ImageComp({ item }) {
  return (
    <Box>
      <img
        alt={item.alt}
        style={{
          objectFit: "cover",
          width: "100%",
        }}
        src={item.url}
      />
    </Box>
  );
}
