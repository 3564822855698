import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SingleToggleGroup from "../../../../common/SingleToggleGroup";
import PaginaVolgorde from "./paginaVolgorde/PaginaVolgorde";
import PageContent from "./page/pageContent";

const toggleButtonItems = [
  { value: "ONLINE", text: "ONLINE" },
  { value: "EDIT", text: "EDIT MODE" },
  { value: "OFFLINE", text: "OFFLINE" },
];

export default function PaginaRow({ data, setData, item, snackbar }) {
  const [rowData, setRowData] = useState(item);
  const [menuName, setMenuName] = useState(item.menuName || "");
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const updateRowData = async () => {
        try {
          const response = await axios.put(
            `api/settings/pages/${rowData._id}`,
            { item: rowData },
          );
          return response.data;
        } catch (error) {
          snackbar(
            "Er is iets misgegaan bij het aanpassen pagina informatie",
            "error",
          );
        }
      };

      updateRowData();
    }
  }, [rowData]);

  // const updateItemsOrder = async (newArray, order) => {
  //   for (const item of newArray) {
  //     if (item.order >= order) {
  //       item.order = item.order - 1;
  //       try {
  //         await axios.put(`api/settings/pages/${item._id}`, { item });
  //       } catch (error) {
  //         snackbar(
  //           "Er is iets misgegaan bij het aanpassen van de volgorde",
  //           "error",
  //         );
  //       }
  //     }
  //   }
  // };

  // const filterOutDeletedItem = (data, id) => {
  //   return data.filter((item) => item._id !== id);
  // };

  // const deletePage = async () => {
  //   try {
  //     await axios.delete(`api/settings/pages/${item._id}`);
  //     const newArray = filterOutDeletedItem(data, item._id);
  //     await updateItemsOrder(newArray, item.order);
  //     setData(newArray);
  //   } catch (error) {
  //     snackbar(
  //       "Er is iets misgegaan bij het verwijderen van de pagina",
  //       "error",
  //     );
  //   }
  // };

  const handleToggleButton = (value) => {
    setRowData((prevState) => ({
      ...prevState,
      visible: value,
    }));
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setRowData((prevState) => ({
      ...prevState,
      menuName: value,
    }));
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: '60px'
          }}
        >
          <div>
            <TextField
              name="menuName"
              id={`naam-${rowData._id}`}
              onChange={(e) => setMenuName(e.target.value)}
              onBlur={handleNameSubmit}
              value={menuName}
            />
          </div>

          <div>
            <SingleToggleGroup
              value={rowData.visible}
              setValue={handleToggleButton}
              items={toggleButtonItems}
              primaryColor
              negativeColor
            />
          </div>
          <div>
            {rowData.order}
            <PaginaVolgorde
              data={data}
              row={rowData}
              id={rowData._id}
              order={rowData.order}
              setData={setData}
              snackbar={snackbar}
            />
          </div>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <PageContent id={rowData._id} />
      </AccordionDetails>
    </Accordion>
  );
}
