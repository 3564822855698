import React from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import ImageUploader from "../common/imageUploader";

export default function AfbeeldingToevoegen({ snackbar, setImages }) {
  async function addImage(item) {
    axios
    .post('/api/sleepingPlan/images', item)
    .then((response) => {
      console.log(response)
      if (response.status === 201) {
        setImages((prevImages) => [...prevImages, response.data]);

      } else {
        throw Error;
      }
    })
    .catch((error) => {
      snackbar("Er is iets misgegaan", "error");
    });
  }

  return (
    <Box>
      <ImageUploader
        text="Selecteer afbeelding"
        setUrl={(url) => {
          const newItem = {
            url,
            alt: "",
            active: true,
          };
          addImage(newItem);
        }}
      />
    </Box>
  );
}
