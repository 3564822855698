import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paragraph from "../../common/Paragraph";
import Tijdlijn from "./Tijdlijn";
import XPress from "../_utils/Xpress";
import theme from "../app/theme";
import { Days } from "../../constants/Days";

const useStyles = makeStyles({
  wideContainer: {
    ...theme.wideContainer,
  },
});
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export function Programma() {
  const [days, setDays] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentDay, setCurrentDay] = useState("");
  const classes = useStyles();

  useEffect(() => {
    XPress.getArray("api/settings/program").then((data) => {
      if (data) {
        const visibleDays = Object.values(Days).filter((day) =>
          data.days.includes(day)
        );
        setDays(visibleDays);
        setCurrentDay(visibleDays[0])
      } else {
        setError(true);
      }
    });
    setLoading(false);
  }, []);

  const handleChangeDay = (event, newValue) => {
    setCurrentDay(newValue);
  };

  if (loading || currentDay === "") {
    return <div>Data aan het laden...</div>;
  }

  if (error) {
    return <div>Er is een fout opgetreden. Probeer het later nog eens.</div>;
  }

  return (
    <>
      {isMobile && (
        <Paragraph paragraph="Swipe naar rechts om alle dagen te zien." />
      )}
      <div className={classes.wideContainer}>
        <AppBar position="static" color="default">
          <Tabs
            value={currentDay}
            onChange={handleChangeDay}
            indicatorColor="primary"
            textColor="primary"
            variant={isMobile ? "scrollable" : "fullWidth"}
            scrollButtons="auto"
            aria-label="scrollable tabs"
          >
            {days.map((day) => {
              return <Tab label={day} key={day} value={day} />;
            })}
          </Tabs>
        </AppBar>
        <Tijdlijn day={currentDay} />
      </div>
    </>
  );
}
