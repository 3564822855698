import React, { useEffect, useState } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import { view } from "@risingstack/react-easy-state";
import settingsStore from "../../store/settingsStore";
import groupStore from "../../store/groupStore";
import { GroepButton } from "./groepen/GroepButton";
import InschrijfBalk from "./InschrijfBalk";
import FlipCard from "../../common/flipCard/FlipCard";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const useStyles = makeStyles({
  flipCardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    width: "85vw",
    marginBottom: "15%",
  },
});
const GroepenOverzicht = view(() => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const classes = useStyles();
  const { useChips, online, visible } = settingsStore.groups;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get("api/settings/group");
        settingsStore.setGroupSettings(data);

        const visibleGroupsResponse = await axios.get("api/groups/visible");
        const visibleGroups = visibleGroupsResponse.data;
        groupStore.addAllToStore(visibleGroups);
      } catch (error) {
        setError(
          "Het lijkt er op dat er iets mis is gegaan.probeer het later nog eens",
        );
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const importGroepen = groupStore.groepen.map(
    ({
      members,
      shortDescription,
      fullDescription,
      maxMembers,
      name,
      points,
      url,
      _id,
    }) => {
      return (
        <FlipCard
          key={_id}
          type="groep"
          backgroundImg={url}
          soldOut={online && members.length >= maxMembers}
          soldText="VOL"
          title={name}
          list2text={useChips && `${points} ${points > 1 ? "punten" : "punt"}`}
          list3text={`${maxMembers} ${maxMembers > 1 ? "plekken" : "plek"}`}
          headerBack={shortDescription}
          textBack={fullDescription}
        >
          <GroepButton
            id={_id}
            name={name}
            members={members}
            maxMembers={maxMembers}
            points={points}
          />
        </FlipCard>
      );
    },
  );

  if (loading) {
    return (
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={370} height={438} />
        <Skeleton variant="rectangular" width={370} height={438} />
      </Stack>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <>
      {visible && (
          <div className={classes.flipCardContainer}>{importGroepen}</div>
      )}
      <InschrijfBalk />
    </>
  );
});

export default GroepenOverzicht;
