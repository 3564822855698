import { store } from "@risingstack/react-easy-state";

const colors = store({
  backgroundState: null,
  backgroundColor: "",
  triangleColor: "",

  setBackgroundColor(color) {
    colors.backgroundColor = color;
  },

  setTriangleColor(color) {
    colors.triangleColor = color;
  },
  setBackgroundState(state) {
    colors.backgroundState = state;
  },
  getButtonColor() {
    return colors.backgroundState === 1 // triangle is left
      ? colors.backgroundColor
      : colors.triangleColor;
  },
});

export default colors;
