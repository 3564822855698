import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Menu from "./menu";
import Faqinzendingen from "./faqinzendingen";
import EigenIdeeInzendingen from "./eigenIdeeInzendingen";
import GroepBeheer from "./aanmeldingen";
import ProgrammaBeheer from "./programmaBeheer";
import FormulierInzendingen from "./formulier";
import Settings from "./settings";
import TentKamerIndeling from "./tentKamerIndeling";
import BootIndeling from "./bootIndeling";
import "./Overview.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Overview() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [feedback, setFeedback] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    let isMounted = true;
    if (window.location.search && isMounted) {
      setActiveTab(Number(window.location.search.substr(1)));
    }

    return () => {
      isMounted = false;
    };
  }, [setActiveTab]);

  const snackbar = (text, severity = "success") => {
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
    setFeedback(text);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback("");
    setOpenSnackbar(false);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="xl">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2800}
          onClose={handleClose}
          message={feedback}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <Alert
            onClose={handleClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {feedback}
          </Alert>
        </Snackbar>
        <Menu activeTab={activeTab} setActiveTab={setActiveTab} />
        <TabPanel value={activeTab} index={0}>
          <Settings snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Faqinzendingen snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <EigenIdeeInzendingen snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          <GroepBeheer snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <ProgrammaBeheer snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={5}>
          <FormulierInzendingen snackbar={snackbar}/>
        </TabPanel>
        <TabPanel value={activeTab} index={6}>
          <TentKamerIndeling snackbar={snackbar} />
        </TabPanel>
        <TabPanel value={activeTab} index={7}>
          <BootIndeling snackbar={snackbar} />
        </TabPanel>
      </Container>
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
