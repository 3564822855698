import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useStyles } from "./ProgramSettings.styles";
import { Days } from "../../../../constants/Days";

export default function ProgramSettings({ settings, snackbar }) {
  const classes = useStyles();
  const [days, setDays] = useState(settings.days);

  const handleDays = (event, newDays) => {
    setDays(newDays);
    axios
      .put(`api/settings/program/${settings._id}`, newDays)
      .catch(() => {
        snackbar(
          "Er is iets misgegaan tijdens het opslaan van de wijzigingen",
          "error",
        );
      });
  };

  return (
    <Paper className={classes.root}>
      <Box p={4} className={classes.settingsWrapper}>
        Dagen zichtbaar in het programma
        <ToggleButtonGroup
          value={days}
          onChange={handleDays}
          aria-label="Dagen aanpassen"
        >
          {Object.values(Days).map((day) => {
            return (
              <ToggleButton value={day} aria-label={day} key={day}>
                {day}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
    </Paper>
  );
}
