import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function PaginaToevoegen({ data, setData }) {
  const [newPage, setNewPage] = useState({
    name: "",
    menuName: "",
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setNewPage((prevNewPage) => ({
      ...prevNewPage,
      [name]: name === "label" ? _.camelCase(value) : value,
    }));
  };

  async function paginaToevoegen(e) {
    e.preventDefault();
    try {
      const highestOrder = data.reduce((maxOrder, item) => {
        return item.order > maxOrder ? item.order : maxOrder;
      }, 0);
      const res = await axios.post("api/settings/pages", {
        ...newPage,
        visible: "EDIT",
        order: highestOrder + 1,
      });
      if (res.status === 201) {
        setData((prev) => [...prev, res.data]);
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box>
      <form onSubmit={(e) => paginaToevoegen(e)} autoComplete="off">
        <Accordion disabled={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="pagina-toevoegen-header"
            id="-pagina-toevoegen"
          >
            <Typography>Voeg een nieuwe pagina toe</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <TextField
                required
                id="label"
                label="Label"
                name="name"
                onChange={handleInputChange}
              />
              <TextField
                required
                id="menuName"
                label="Naam in menu"
                name="menuName"
                onChange={handleInputChange}
              />
              <Button type="submit" variant="contained" color="primary">
                Voeg toe
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </form>
    </Box>
  );
}
