import React, { useEffect, useState } from "react";
import XPress from "../_utils/Xpress";
import "./Brievenbus.css";
import Faqvraag from "./faqVraag";
import StuurVraag from "./stuurVraag";

export function Brievenbus() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    XPress.getArray("api/faq/visible").then((data) => {
      if (data) {
        setLoading(false);
        setQuestions(data);
      } else {
        setError(true);
      }
    });
  }, []);

  if (loading) {
    return <div>Aan het laden...</div>;
  }

  if (error) {
    return <div>Kon de data niet laden. probeer het later nog eens.</div>;
  }
  return (
    <>
      {questions.map((question) => {
        return <Faqvraag question={question} key={question._id} />;
      })}
      <StuurVraag />
    </>
  );
}
