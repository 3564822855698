import React from "react";
import axios from "axios";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";

export default function PaginaVolgorde({ id, row, data, setData, snackbar }) {
  const { order } = row;

  const handleOrderChange = async (direction) => {
    const rowIndex = data.findIndex((item) => item._id === id);
    const newOrder = direction === "up" ? order - 1 : order + 1;
    const updatedItem = { ...row, order: newOrder };

    const originalItemIndex = direction === "up" ? rowIndex - 1 : rowIndex + 1;
    const itemCurrentlyOnThisOrder = data[originalItemIndex];
    const updatedOriginalItem = { ...itemCurrentlyOnThisOrder, order };
    try {
      await axios.put(`api/settings/pages/${id}`, { item: updatedItem });
      await axios.put(`api/settings/pages/${itemCurrentlyOnThisOrder._id}`, {
        item: updatedOriginalItem,
      });
    } catch (error) {
      snackbar("Error bij het aanpassen van de volgorde", "error");
    }
    setData((prevState) => {
      prevState[rowIndex].order = newOrder;
      prevState[originalItemIndex].order = updatedOriginalItem.order;
      return [...prevState];
    });
  };

  return (
    <>
      <IconButton
        aria-label="up"
        disabled={order === 1}
        onClick={() => handleOrderChange("up")}
        size="large"
      >
        <ArrowDropUpIcon />
      </IconButton>
      <IconButton
        aria-label="down"
        disabled={order === data.length}
        onClick={() => handleOrderChange("down")}
        size="large"
      >
        <ArrowDropDownIcon />
      </IconButton>
    </>
  );
}
