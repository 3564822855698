import React from "react";
import AwesomeSlider from "react-awesome-slider";
import Typography from "@mui/material/Typography";
import { useStyles } from "./ImageSlider.styles";
import styles from "./ThrowBackSlider.css";

export default function ImageSlider({ images }) {
  const classes = useStyles();

  return (
    <>
      {images.length && (
        <>
          {images.length > 1 ? (
            <AwesomeSlider className=".aws-btn" cssModule={styles}>
              {images.map(({ _id, url, alt }) => (
                <div key={_id} data-src={url} className={classes.imgDiv}>
                  {alt && (
                    <div className={classes.imgTxt}>
                      <Typography variant="h4">{alt}</Typography>
                    </div>
                  )}
                </div>
              ))}
            </AwesomeSlider>
          ) : (
            <>
              <div className={classes.imgDiv}>
                <img
                  src={images[0].url}
                  alt={images[0].alt}
                  className={classes.img}
                />
                <div className={classes.imgTxt}>
                  <Typography variant="h4">{images[0].alt}</Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
