import React from "react";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Aanmeldingen({
  members,
  deleteMemberFromGroup,
  disabled,
}) {
  if (!members.length) {
    return <div>Er zijn nog geen aanmeldingen voor deze groep</div>;
  }

  return (
    <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>Datum</TableCell>
          <TableCell>Naam</TableCell>
          <TableCell align="left">Berichtje</TableCell>
          <TableCell align="left">Verwijderen</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map((member) => (
          <TableRow key={member._id || `${member.name} ${member.surname}`}>
            <TableCell align="left">
              <span>{new Date(member.date).toLocaleString("nl")}</span>
            </TableCell>

            <TableCell component="th" scope="row">
              {member.name} {member.surname}
            </TableCell>
            <TableCell align="left">{member.motivation}</TableCell>
            <TableCell align="left">
              <IconButton
                aria-label="delete"
                disabled={disabled}
                onClick={() =>
                  window.confirm(
                    "Weet je zeker dat je deze aanmelding wilt verwijderen?",
                  ) && deleteMemberFromGroup(member._id)
                }
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
