import colors from "../app/store";

const colorTriangle = [
  "#ff9e6a",
  "#8ff9bc",
  "#ff8d9f",
  "#ff9e6a",
  "#8ff9bc",
  "#ff8d9f",
];

const colorBackground = [
  "#faffd4",
  "#d2ffff",
  "#ffe6fe",
  "#faffd4",
  "#d2ffff",
  "#ffe6fe",
];

function changeColour(index, element, setterFunction, delay) {
  colors.setBackgroundState(index % 2);

  if (element === "triangle") {
    const colorIndex = index / 2 - (index % 2) / 2;
    setterFunction(colorTriangle[colorIndex]);
    colors.setTriangleColor(colorTriangle[colorIndex]);
  } else if (element === "background") {
    const colorIndex = index / 2 + (index % 2) / 2;

    setTimeout(() => {
      colors.setBackgroundColor(colorBackground[colorIndex]);
      setterFunction(colorBackground[colorIndex]);
    }, delay || 0);
  }
}
export default changeColour;
