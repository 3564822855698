import React, { useState } from "react";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Checkbox, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles({
  deleteIcon: {
    position: "absolute",
    right: 0,
    margin: "2%",
    cursor: "pointer",
  },
  img: {
    maxWidth: 195,
    maxHeight: 195,
  },
  imgContainer: {
    width: 200,
    height: 200,
    border: "1px solid lightgrey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imgDisplayer: {
    display: "flex",
  },
  imgItem: {
    border: "1px solid lightgrey",
    position: "relative",
    margin: "0 0.5%",
  },
  smallColumn: {
    maxWidth: "100px",
  },
});

export default function Afbeelding({ image, images, snackbar, setImages }) {
  const classes = useStyles();
  const [altValue, setAltValue] = useState(image.alt);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const handleChange = (_id, url, title, status) => {
    setImages(
      images.map((item) =>
        item._id === _id
          ? {
              _id,
              url,
              alt: title,
              active: status,
            }
          : item,
      ),
    );
    axios
    .put(`/api/sleepingPlan/images/${image._id}`, {
      url,
      alt: title,
      active: status,
    })
    .catch((error) => {
      snackbar("Er is iets misgegaan", "error");
    });
  };

  const handleDeleteImage = () => {
    axios
      .delete(`/api/sleepingPlan/images/${image._id}`)
      .then((response) => {
        if (response.status === 200) {
          setImages((prevImages) =>
            prevImages.filter((i) => i._id !== image._id),
          );
        } else {
          throw Error;
        }
      })
      .catch((error) => {
        snackbar("Er is iets misgegaan", "error");
      });
  };

  return (
    <Box
      className={classes.imgItem}
      onMouseEnter={() => setShowDeleteIcon(!showDeleteIcon)}
      onMouseLeave={() => setShowDeleteIcon(!showDeleteIcon)}
    >
      {showDeleteIcon && (
        <DeleteIcon
          color="primary"
          className={classes.deleteIcon}
          onClick={handleDeleteImage}
        />
      )}
      <div className={classes.imgContainer}>
        <img src={image.url} alt={image.alt} className={classes.img} />
      </div>
      <Box className={classes.activeCheckbox}>
        Online:{" "}
        <Checkbox
          checked={image.active}
          onClick={() =>
            handleChange(image._id, image.url, image.alt, !image.active)
          }
        />
      </Box>
      <TextField
        label="Titel"
        variant="standard"
        value={altValue}
        onChange={(e) => setAltValue(e.target.value)}
        onBlur={() =>
          handleChange(image._id, image.url, altValue, image.active)
        }
      />
    </Box>
  );
}
