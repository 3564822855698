import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const useStyles = makeStyles({
  paragraphMargins: {
    margin: "5% 0",
    ["@media (max-width:1000px)"]: {
      maxWidth: "0 10%",
    },
  },
  typography: {
    fontSize: (props) => props.fontSize,
    ["@media (max-width:500px)"]: {
      fontSize: (props) => props.mobileFontSize,
    },
    whiteSpace: "pre-line",
  },
});

function Paragraph(props) {
  const {
    paragraph = undefined,
    textAlign = "inherit",
    children = undefined,
  } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.paragraphMargins}>
      {paragraph && (
        <Typography
          variant="body1"
          className={classes.typography}
          style={{ textAlign: textAlign }}
        >
          {paragraph}
        </Typography>
      )}
      {children}
    </Box>
  );
}

export default Paragraph;
