import React from "react";
import { Box, IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDrag, useDrop } from "react-dnd";
import { ParagraphComp, ImageComp, VideoComp, HeaderComp } from "./content";
import { styles } from "./styled";

const itemName = {
  header: "Pagina header",
  paragraph: "Tekstblok",
  video: "Video",
  image: "Afbeelding",
};

export function Item({
  item,
  index,
  canDrag,
  moveItem,
  deleteItem,
  updateContentItem,
}) {
  const [, drag, preview] = useDrag(
    () => ({
      type: "item",
      item: { type: item.type, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [item.type, index],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "item",
      drop: (item) => {
        if (item.index !== index) {
          moveItem(item.index, index);
        }
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [moveItem],
  );

  const renderItem = () => {
    switch (item.type) {
      case "paragraph":
        return (
          <ParagraphComp item={item} updateContentItem={updateContentItem} />
        );

      case "image":
        return <ImageComp item={item} updateContentItem={updateContentItem} />;

      case "video":
        return <VideoComp item={item} updateContentItem={updateContentItem} />;

      case "header":
        return <HeaderComp item={item} updateContentItem={updateContentItem} />;

      default:
        break;
    }
  };

  return (
    <Accordion
      ref={drop}
      style={{
        opacity: isOver ? 0.5 : 1,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} ref={preview}>
        <Box
          sx={styles.accordionSummary}
          style={{
            backgroundColor: isOver ? "gray" : "white",
          }}
        >
          <Box>{itemName[item.type]}</Box>
          <Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deleteItem(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
            {canDrag && (
              <IconButton sx={{ cursor: "move" }} ref={drag}>
                <DragIndicatorIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{renderItem()}</AccordionDetails>
    </Accordion>
  );
}
