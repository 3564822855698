import React from "react";
import "./Formulier.css";
import { FormulierForm } from "./FormulierForm";

export function Formulier() {
  return (
    <>
      <FormulierForm />
    </>
  );
}
