export const styles = {
  addItemBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  checkboxItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  imageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
  },
  text: {
    whiteSpace: "pre-line",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gridGap: "16px",
  },
  root: {
    width: "100%",
  },
};
