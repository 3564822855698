import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  container: {
    padding: "8px",
  },
  newMemberFormContainer: {
    display: "flex",
    gap: "2px",
  },
});
