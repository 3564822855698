import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material/";
import { useStyles } from "./GroepenInstellingen.styles";

export default function GroepenInstellingen({ settings, snackbar }) {
  const classes = useStyles();
  const [initialRender, setInitialRender] = useState(true);
  const [visible, setVisible] = useState(settings.visible);
  const [online, setOnline] = useState(settings.online);
  const [useChips, setUseChips] = useState(settings.useChips);

  function updateItem() {
    axios
      .put(`api/settings/group/${settings._id}`, {
        visible,
        online,
        useChips,
      })
      .then(() => {
        snackbar("Instellingen zijn gewijzigd");
      })
      .catch(() => {
        snackbar(
          "Er is iets misgegaan tijdens het opslaan van de wijzigingen",
          "error",
        );
      });
  }

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
    } else {
      updateItem();
    }
  }, [visible, online, useChips]);

  return (
    <Paper className={classes.root}>
      <Box p={4} className={classes.settingsWrapper}>
        <Typography variant="h5">Instellingen</Typography>
        <Box>
          <Box className={classes.item}>
            groepen zichtbaar:{" "}
            <Checkbox
              checked={visible}
              onChange={(e) => setVisible(e.target.checked)}
              name="groepen visible"
              color="primary"
              inputProps={{ "aria-label": "groepen visible checkbox" }}
            />
          </Box>
          <Box className={classes.item}>
            groepen online:{" "}
            <Checkbox
              checked={online}
              onChange={(e) => setOnline(e.target.checked)}
              name="groepen online"
              color="primary"
              inputProps={{ "aria-label": "groepen online checkbox" }}
            />
          </Box>
          <Box className={classes.item}>
            Puntensysteem actief:{" "}
            <Checkbox
              checked={useChips}
              onChange={(e) => setUseChips(e.target.checked)}
              name="puntensysteem actief"
              color="primary"
              inputProps={{ "aria-label": "puntensysteem actief checkbox" }}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
