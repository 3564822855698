import makeStyles from "@mui/styles/makeStyles";
import zwart200px from "../../images/zwart200px.png";

export const useStyles = makeStyles({
  paper: {
    margin: "0.5% 0",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    backgroundColor: "black",
  },
  headerColoredTopBorder: {
    backgroundImage: ({ itemAttributes }) => `url(${itemAttributes.topbar})`,
    backgroundSize: "cover",
    height: "5px",
    width: "100%",
  },
  paperContent: {
    alignItems: "center",
    padding: "6px 16px",
  },

  leftSide: {
    width: "35px",
    display: "inline-flex",
    alignItems: "center",
  },
  title: {
    fontFamily: "Cairo, sans-serif",
    fontWeight: "bold",
    color: "white",
    fontSize: "1.9em",
    lineHeight: "1.1",
    textAlign: "right",
    maxWidth: "84%",
    ["@media (max-width:500px)"]: {
      fontSize: "5.2vw",
      maxWidth: "75%",
    },
  },
  textSumUp: {
    fontSize: "1.05em",
    color: "white",
    paddingLeft: "10px",
    listStyleType: "none",
    lineHeight: "1.4em",
    ["@media (max-width:500px)"]: {
      fontSize: "3.8vw",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundImage: `url(${zwart200px})`,
    backgroundPosition: ({ backgroundPos }) => backgroundPos,
    backgroundSize: "cover",
  },
  paperBody: {
    height: "100%",
    padding: "2% 0 1% 0",
  },
});
