import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDhpYkfN8kApKGTdHrXm2U6ZX7MbCr9oSc",
  authDomain: "kv-images.firebaseapp.com",
  projectId: "kv-images",
  storageBucket: "kv-images.appspot.com",
  messagingSenderId: "356127610124",
  appId: "1:356127610124:web:9b745b2c76c47982646529",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const storage = firebase.storage();

export { storage, firebase as default };
