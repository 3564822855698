import React, { useState, useEffect } from "react";
import TimelineDot from "@mui/lab/TimelineDot";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { iconPicker } from "./iconPicker";
import { useStyles } from "./TijdlijnItem.styles";
import { randomBackgroundPosition } from "../_utils/randomBackgroundPosition";
import { Days } from "../../constants/Days";

export default function TijdlijnItem(props) {
  const { time, icon, title, text, day } = props.value;
  const itemAttributes = iconPicker(icon);
  const [open, setOpen] = useState(false);
  const [backgroundPos, setBackgroundPos] = useState();
  const SelectedIcon = itemAttributes.logo;
  const classes = useStyles({ itemAttributes, backgroundPos });

  useEffect(() => {
    setBackgroundPos(randomBackgroundPosition);
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Paper className={classes.paper} onClick={toggleOpen}>
        <div className={classes.headerColoredTopBorder}></div>
        <div className={classes.paperContent}>
          <div className={classes.header}>
            <div className={classes.leftSide}>
              <TimelineDot style={itemAttributes.styling}>
                <SelectedIcon />
              </TimelineDot>
              <Typography className={classes.textSumUp}>
                {day !== Days.HELE_WEEKEND && time}
              </Typography>
            </div>
            <Typography className={classes.title}>
              {title.toUpperCase()}
            </Typography>
          </div>
          {open && (
            <div className={classes.paperBody}>
              <Typography className={classes.textSumUp}>{text}</Typography>
            </div>
          )}
        </div>
      </Paper>
    </>
  );
}
