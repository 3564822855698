import React from "react";
import { useState } from "react";
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import { styles } from "./styled";

export default function VideoComp({
  item: { id, type, url, autoplay },
  updateContentItem,
}) {
  const [urlValue, setUrlValue] = useState(url);
  const [autoplayValue, setAutoplayValue] = useState(autoplay);
  console.log(autoplay)
  const handleBlur = () => {
    updateContentItem(id, {
      id,
      type,
      url: urlValue,
      autoplay: autoplayValue,
    });
  };

  const handleAutoPlay = (checked) => {
    setAutoplayValue(checked)
    updateContentItem(id, {
      id,
      type,
      url: urlValue,
      autoplay: checked,
    });
  }

  return (
    <Box sx={styles.wrapper}>
      <TextField
        name="url"
        value={urlValue}
        placeholder="Vimeo URL !Let op alleen vimeo links worden ondersteund"
        fullWidth
        onBlur={handleBlur}
        onChange={(event) => setUrlValue(event.target.value)}
      />
      <Box sx={styles.checkboxItem}>
        <Typography>video speelt automatisch af </Typography>
        <Checkbox
          checked={autoplayValue}
          onChange={(event) => handleAutoPlay(event.target.checked)}
        />
      </Box>
    </Box>
  );
}
