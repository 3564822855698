import * as React from "react";
import TextField from "@mui/material/TextField";

export default function TextFieldInput(props) {
  const {
    label,
    required = false,
    value,
    multiline = false,
    handleChange,
    rows = undefined,
    placeholder,
    margin = "none",
  } = props;

  return (
    <TextField
      id="filled-basic"
      label={label}
      variant="filled"
      value={value}
      required={required}
      onChange={handleChange}
      multiline={multiline}
      rows={rows}
      placeholder={placeholder}
      fullWidth
      margin={margin}
    />
  );
}
