import { store } from "@risingstack/react-easy-state";

const userStore = store({
  userInfo: undefined,

  setUserInfo(userInfo) {
    userStore.userInfo = userInfo;
  },
});

export default userStore;
