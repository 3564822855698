import React from "react";
import { useDrop } from "react-dnd";
import { Item } from "./Item/Item";
import { styles } from "./styled";
import { Box, Typography } from "@mui/material";
import { nanoid } from "nanoid";
import { ContentType } from "../../../../../../constants/ContentType";

const getInitialContentItem = (type) => {
  switch (type) {
    case "image":
      return {
        id: nanoid(),
        type: ContentType.IMAGE,
        url: "",
        alt: "",
      };

    case "video":
      return {
        id: nanoid(),
        type: ContentType.VIDEO,
        url: "",
        autoplay: true,
      };
    case "header":
      return {
        id: nanoid(),
        type: ContentType.HEADER,
        data: [],
      };
    default:
      return {
        id: nanoid(),
        header: "",
        type: ContentType.PARAGRAPH,
        text: "",
      };
  }
};

export function RightContent({ content, setContent, updateContentItem }) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: Object.values(ContentType).map((t) => t),
      drop: (item, monitor) => {
        if (monitor.didDrop()) {
          return;
        }
        setContent([...content, getInitialContentItem(item.type)]);
      },
      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),
    }),
    [content],
  );

  function moveItem(dragIndex, hoverIndex) {
    const draggedItem = content[dragIndex];
    const newContent = [...content];
    newContent.splice(dragIndex, 1);
    newContent.splice(hoverIndex, 0, draggedItem);
    setContent(newContent);
  }

  const deleteItem = (index) => {
    if (window.confirm("Weet je zeker dat je dit item wilt verwijderen?")) {
      setContent((items) => {
        return items.filter((_, i) => i !== index);
      });
    }
  };

  const isActive = canDrop && isOver;
  return (
    <Box>
      {content.map((item, index) => (
        <Item
          key={index}
          item={item}
          index={index}
          canDrag={content.length > 1}
          moveItem={moveItem}
          deleteItem={deleteItem}
          updateContentItem={updateContentItem}
        />
      ))}
      <Box
        ref={drop}
        sx={styles.dropzone}
        style={{
          backgroundColor: isActive ? "lightgray" : "white",
        }}
      >
        <Box
          sx={{
            padding: '8px',
            textAlign: "center",
          }}
        >
          <Box sx={{
            border: "2px dotted lightgray",
            padding: '40px',
            margin: '4px',
          }}>
          <Typography>Sleep elementen hier naar toe</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
