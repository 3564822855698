import React from "react";
import ReactPlayer from "react-player";
import Paragraph from "../../common/Paragraph";
import Header from "../../common/Header";
import ThrowBackSlider from "../../common/ThrowBackSlider";

export function Throwback() {
  return (
    <>
      <Header header="2016" />

      <Paragraph
        paragraph="De eerste editie van Karavaan. Toen Karavaan nog in de
              letterlijke definitie van het woord beleefd werd. Vertrekkend uit
              Leiden begaven we ons per fiets naar een klein eilandje op de
              Vinkeveense Plassen, waar we van niets iets moesten maken. Van
              tenten, muziekinstallatie tot een generator werkend op diesel aan
              toe; alles moest meegenomen worden. Dansend in de zon op de
              vlonder hoopte iedereen dat dit een terugkerend dingetje zou
              worden."
      />
      <ThrowBackSlider year={2016} numberOfImages={16} />
      <Header header="2020" />
      <Paragraph
        paragraph="en een terugkerend ding werd het. Met een kleine wisseling in
        de organisatie en iets meer hang naar comfort kwam daar Karavaan
        Deluxe. Hét lichtpuntje van het door Corona verpeste festivalseizoen.
        Van vrijdag- tot zondagmiddag 50 mensen met energie voor 100, elk uur
        een andere activiteit, weergaloze plaatjes en het gevoel dat het leven
        toch weer even normaal was. Met De Kluut als stralende middelpunt werd
        al vrij snel besloten dat dit weekend in de herhaling moest."
      />
      <ThrowBackSlider year={2020} numberOfImages={15} />
      <Header header="2021" />
      <Paragraph
        paragraph="Hoewel over het weer tijdens de zomer van 2021 niet over naar huis te schrijven viel, scheen tijdens de derde editie van Karavaan het hele weekend de zon. Met een dag extra kon je pas echt aan alles mee doen en tegelijkertijd ook nog de kans grijpen om bij te slapen (al hebben genoeg mensen het aanbreken van de dag meegemaakt). Naast nieuwe activiteiten, zoals de safari en een creatieve workshop was er ook weer plaats voor de ouwe gouwe nummertjes; het Rad van Dans met Wans, ochtendgymnastiek met Mr B. The Slideman en de opblaasdieren escalatie extravaganza; die nu wél ter water eindigde. 
        Kort samengevat; het concept Karavaan leek geperfectioneerd te zijn. Betekent niet dat we niet hard ons best gaan doen om de volgende editie te laten overtreffen. "
      />
      <ReactPlayer
        url="https://vimeo.com/657750264"
        controls={true}
        width="100%"
      />
      <ThrowBackSlider year={2021} numberOfImages={17} />
      <Header header="2022" />
      <Paragraph paragraph="De voorgaande twee edities op de Kluut culmineerden in een (nagenoeg) perfecte derde editie. Naast de gevestigde klassiekertjes, kon je verdwalen in de slinky forest, dansen en twisteren bij de Secret Stage, wegspacen in de kubus van Thijs en speurend bier adten bij het Mysterie van De Kluut. DJ’s als Andrea Bartori, Snail of Us en Aladin on Acid hebben hun intrede gedaan en de muziek naar hogere sferen getild. ’s Ochtends de zon op zien komen in de haven, de nacht doortrekken met de ochtendshow van Hugo en de hyperactieve Opblaasdieren Extravanganza 3.0 XL resulteerden in een fysieke uitputtingsslag, die gelukkig werd gecompenseerd met vitaminerijke bikkesementen en de Spa Total Relax-a-Zen." />
      <Paragraph paragraph="Van vele kanten hoorden we dat dit misschien toch wel de beste editie ooit was en dat we de lat de lat hoger dan ooit hebben gelegd..  " />
      <ThrowBackSlider year={2022} numberOfImages={14} />

      <Header header="Winter 2023" />
      <Paragraph paragraph="De eerste maanden van een nieuw jaar kenmerken zich vaak door treurnis. Alle festiviteiten van het eind van vorig jaar liggen achter je, het regent en storm bijna dagelijks, je goede voornemens zijn alweer de prullenbak in en je hebt de komende tijd helemaal niets leuks om naar uit te kijken. Dat wordt dus weer pijn lijden, tot het eindelijk lente is." />
      <Paragraph paragraph="Althans, dat was het geval in alle jaren vóór februari 2023. Want toen was er ineens Château Karavaan, wat tijdens de donkere dagen ons licht in de duisternis werd. Château Karavaan hield de principes die we kennen van de zomers op De Kluut in stand." />
      <Paragraph paragraph="In Middeleeuwse sferen bestreden Karavaangangers elkaar tijdens uitdagende opdrachten, maar omhelsden ze elkaar in de nachten, zoals dat hoort. Het werd net zo onvergetelijk als de avonturen die we de jaren hiervoor in de zon beleefden." />
      <ReactPlayer
        url="https://vimeo.com/813479125"
        controls={true}
        width="100%"
      />
    </>
  );
}
