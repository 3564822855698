import React, { useState } from "react";
import { capitalize } from "lodash";
import axios from "axios";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import PaletteIcon from "@mui/icons-material/Palette";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import TerrainIcon from "@mui/icons-material/Terrain";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import FlareIcon from "@mui/icons-material/Flare";

import { Days } from "../../../../constants/Days";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableRoot: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  groepBox: {
    width: 100,
    height: 100,
    border: "2px solid black",
    backgroundColor: "lightgray",
  },
  column: {
    width: "33%",
  },
});

export default function GroepRow(props) {
  const { item } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(item);
  const [aanpasButton, toggleAanpasButton] = useState(false);

  const handleInputChange = (attribute) => (e) => {
    let value = e.target.value;
    setData((prevState) => {
      prevState[attribute] = value;
      return {
        ...prevState,
      };
    });
  };

  const handleCheckBoxInputChange = (attribute) => (e) => {
    let value = e.target.checked;
    setData((prevState) => {
      prevState[attribute] = value;
      return {
        ...prevState,
      };
    });
  };

  const handleButtonClick = (e, button) => {
    setData({
      ...data,
      icon: button,
    });
  };

  const toggleEdit = () => {
    if (!open) {
      setOpen(true);
    }
    if (aanpasButton && open) {
      setOpen(false);
    }

    if (aanpasButton) {
      axios
        .put(`api/program/${data._id}`, data)
        .then((response) => {
          // Handle response if needed
          props.snackbar("Succesvol geupdate!");
        })
        .catch((error) => {
          // Handle error if the update fails
          props.snackbar("Er is iets fout gegaan", "error");
        });
    }
    toggleAanpasButton(!aanpasButton);
  };

  const deleteItem = (id) => {
    axios
      .delete(`api/program/${id}`)
      .then((res) => {
        if (res.status === 200) {
          props.updateProp(props.updateState);
        } else {
          props.snackbar("Er is iets fout gegaan", "error");
        }
      })
      .catch((error) => {
        props.snackbar("Er is iets fout gegaan", "error");
      });
  };

  return (
    <React.Fragment>
      <TableRow className={classes.tableRoot}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">
          <TextField
            id={`title-${data._id}`}
            onChange={handleInputChange("title")}
            disabled={!aanpasButton}
            defaultValue={data.title}
          />
        </TableCell>
        <TableCell align="right">
          <Select
            disabled={!aanpasButton}
            required
            native
            value={data.day}
            inputProps={{
              name: "day",
              id: `day-${data._id}`,
            }}
            onChange={handleInputChange("day")}
          >
            {Object.values(Days).map((day) => {
              return <option value={day} key={day}>{capitalize(day)}</option>;
            })}
          </Select>
        </TableCell>
        <TableCell align="right">
          <TextField
            id={`time-${data._id}`}
            label="Tijd"
            type="time"
            defaultValue={data.time}
            disabled={!aanpasButton}
            onBlur={handleInputChange("time")}
            inputProps={{
              step: 300, // 5 min
            }}
          />
        </TableCell>
        <TableCell align="right">
          <Checkbox
            disabled={!aanpasButton}
            checked={data.online}
            onChange={handleCheckBoxInputChange("online")}
            name={item._id}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell align="right">
          <Button
            id={`verwijderknop-${data._id}`}
            variant="contained"
            disabled={!aanpasButton}
            color="secondary"
            onClick={() =>
              window.confirm(
                "Weet je zeker dat je deze groep wilt verwijderen?",
              ) && deleteItem(item._id)
            }
          >
            Verwijder
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={() => toggleEdit()}
          >
            {aanpasButton ? "Update" : "Aanpassen"}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TableRow className={classes.tableRoot}>
                <TableCell></TableCell>
                <TableCell align="left">Icon</TableCell>
                <TableCell align="left">
                  <ToggleButtonGroup
                    value={data.icon}
                    exclusive
                    onChange={handleButtonClick}
                    aria-label="icon-selector"
                  >
                    <ToggleButton
                      value="icon1"
                      disabled={!aanpasButton}
                      aria-label="icon1"
                    >
                      <LocalDiningIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon2"
                      disabled={!aanpasButton}
                      aria-label="icon2"
                    >
                      <OutdoorGrillIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon3"
                      disabled={!aanpasButton}
                      aria-label="icon3"
                    >
                      <FastfoodIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon4"
                      disabled={!aanpasButton}
                      aria-label="icon4"
                    >
                      <PhotoCameraIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon5"
                      disabled={!aanpasButton}
                      aria-label="icon5"
                    >
                      <LocalBarIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon6"
                      disabled={!aanpasButton}
                      aria-label="icon6"
                    >
                      <PaletteIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon7"
                      disabled={!aanpasButton}
                      aria-label="icon7"
                    >
                      <ThreeSixtyIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon8"
                      disabled={!aanpasButton}
                      aria-label="icon8"
                    >
                      <SmokingRoomsIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon9"
                      disabled={!aanpasButton}
                      aria-label="icon9"
                    >
                      <TerrainIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon10"
                      disabled={!aanpasButton}
                      aria-label="icon10"
                    >
                      <QueueMusicIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="icon11"
                      disabled={!aanpasButton}
                      aria-label="icon11"
                    >
                      <FlareIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableRoot}>
                <TableCell></TableCell>
                <TableCell align="left">Omschrijving</TableCell>
                <TableCell align="left">
                  <TextField
                    id={`text-${data._id}`}
                    onChange={handleInputChange("text")}
                    disabled={!aanpasButton}
                    fullWidth
                    multiline
                    defaultValue={data.text}
                  />
                </TableCell>
              </TableRow>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
