import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  item: {
    display: "flex",
    maxWidth: "230px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  settingsWrapper: {
    display: "flex",
    flexDirection: "column",
    gridGap: "16px",
  },
  root: {
    width: "100%",
  },
});
