export const styles = {
  wrapper: {
    padding: "5px",
  },
  gridItem: {
    height: '100%',
    backgroundColor: "#f5f5f5",
    padding: '4px',
    textAlign: "center",
    color: 'white',
    flex: 1,
  },
};
