import React from "react";
import Button from "@mui/material/Button";
import { view } from "@risingstack/react-easy-state";
import groupStore from "../../../store/groupStore";
import settingsStore from "../../../store/settingsStore";

export const GroepButton = view(({ id, name, members, maxMembers, points }) => {
  const handleChange = () => {
    groupStore.add({
      _id: id,
      name,
      points: points,
      maxMembers: maxMembers,
    });
  };

  const buttonText = () => {
    if (!settingsStore.groups.online) {
      return "Inschrijven niet mogelijk";
    }
    if (groupStore.isGeselecteerd(id)) {
      return "Geselecteerd";
    }
    if (members.length < maxMembers) {
      return "Selecteren";
    }
    return "Vol";
  };

  return (
    <>
      <Button
        disabled={
          members.length >= maxMembers ||
          groupStore.isGeselecteerd(id) ||
          !settingsStore.groups.online
        }
        onClick={handleChange}
      >
        {buttonText()}
      </Button>
    </>
  );
});
