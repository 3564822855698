import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageSlider from "../../../common/ImageSlider";

export default function Preview({ images }) {
  return (
    <Accordion disabled={!images.length}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="preview-header"
        id="preview"
      >
        <Typography>Display preview</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Bij 1 afbeelding wordt alleen die afbeelding getoond. Bij meerdere
          afbeeldingen komt er een slider die afbeeldingen automatisch
          bijsnijdt.
        </Typography>
        <ImageSlider images={images.filter((i) => i.active)} />
      </AccordionDetails>
    </Accordion>
  );
}
