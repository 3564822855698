import React from "react";
import { useDrag } from "react-dnd";
import { Box, Typography, IconButton } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

export function LeftMenuItem({ itemType, name }) {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: itemType,
    item: { type: itemType, name: name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <>
      <Box
        ref={drag}
        sx={{
          opacity: isDragging ? 0.5 : 1,
          display: "flex",
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: "move",
          backgroundColor: "white",
          borderRadius: "0.25rem",
          border: "1px solid lightgray",
          marginBottom: "1px",
          padding: '4px',
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        }}
      >
          <Typography ref={dragPreview}>{name}</Typography>
          <IconButton>
            <DragIndicatorIcon />
          </IconButton>
      </Box>
    </>
  );
}
