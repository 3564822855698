import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@mui/material";

export const useStyles = makeStyles({
  iconContainer: {
    margin: "-3%",
    bottom: "7%",
    right: "7%",
    display: "flex",
    position: "fixed",
    zIndex: 4,
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    ["@media (max-width:500px)"]: {
      bottom: "5%",
    },
  },
  iconWrapper: {
    width: 60,
    height: 60,
    borderRadius: "100%",
    backgroundColor: "rgba(255,255,255,0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.7)",
    },
    ["@media (max-width:500px)"]: {
      width: 40,
      height: 40,
    },
  },
  icon: {
    width: "75%",
    height: "75%",
    color: "rgba(0,0,0,0.3)",
    "&:hover": {
      color: "rgba(0,0,0,0.7)",
    },
  },
});

const handleClick = () => {
  window.open("https://www.instagram.com/instakaravaan");
};

function InstagramLink() {
  const classes = useStyles();
  return (
    <Box className={classes.iconContainer}>
      <Tooltip title="Er is zelfs een Instagram!" placement="top">
        <Box className={classes.iconWrapper} onClick={handleClick}>
          <InstagramIcon className={classes.icon} />
        </Box>
      </Tooltip>
    </Box>
  );
}

export default InstagramLink;
