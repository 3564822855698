import { store } from "@risingstack/react-easy-state";

const settingsStore = store({
  general: {},
  groups: {
    visible: false,
    online: false,
    useChips: false,
  },
  setGeneralSettings(settings) {
    settingsStore.general = settings;
  },
  setGroupSettings(currentSettings) {
    settingsStore.groups = currentSettings;
  },
});

export default settingsStore;
