import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  buttonContainer: {
    marginTop: "4%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    ...theme.button,
    marginBottom: "1%",
  },
  groepnaamContainer: {
    display: "flex",
    maxWidth: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid darkgrey",
    marginBottom: 12,
  },
  volleGroep: {
    border: "2px solid red",
  },
  errorText: {
    color: "red",
  },
}));
