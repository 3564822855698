import React from "react";
import Title from "../../../common/Title";

export default function HeaderComp({ item }) {
  if (!item.pageHeader) {
    return <div />
  }

  return <Title title={item.pageHeader} />;
}
