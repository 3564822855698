import React, { useState } from "react";
import axios from "axios";
import "./EigenIdee.css";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import TextFieldInput from "../../common/TextField";

const useStyles = makeStyles((theme) => ({
  button: theme.button,
}));

export function EigenIdee() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [idea, setIdea] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = {
      name,
      idea,
    };

    try {
      await axios.post("/api/creativeIdeas", form, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setName("");
      setIdea("");
      setSent(true);
    } catch (error) {
      setError("Er is iets fout gegaan. probeer het later nog eens");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <span className="formtext"></span>
      <TextFieldInput
        value={name}
        required
        handleChange={(event) => setName(event.target.value)}
        placeholder="Wie ben je / wie zijn jullie"
        margin="normal"
      />
      <TextFieldInput
        value={idea}
        handleChange={(event) => setIdea(event.target.value)}
        required
        multiline
        rows={6}
        placeholder="Omschrijf het idee in max 10 zinnen en geef een schatting van het benodigde budget"
      />
      {sent && "Verstuurd! Je hoort snel van ons"}
      {error && <span>{error}</span>}
      <div className="buttondiv">
        <Button type="submit" className={classes.button}>
          Verstuur
        </Button>
      </div>
    </form>
  );
}
